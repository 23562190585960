export default [
  {
    name: 'Admiral Boomhowler',
    type: 'Warlock',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/AdmiralBoomhowler.jpg',
    size: '40mm',
    health: 18,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Braghen Ragemonger',
    type: 'Warlock',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/BraghenRagemonger.jpg',
    size: '50mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Captain Firequill',
    type: 'Warlock',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/CaptainFirequill.jpg',
    size: '40mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 0,
  },
  {
    name: 'Captain Shadowtongue',
    type: 'Warlock',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/CaptainShadowtongue.jpg',
    size: '40mm',
    health: 17,
    fury: 8,
    maxMeleeRange: 0,
  },
  {
    name: 'Commodore Foulblood',
    type: 'Warlock',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/CommodoreFoulblood.jpg',
    size: '40mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Chum',
    type: 'Warbeast',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/Chum.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMM\nBBBBBBBB\nBB\nBBBBBBB\nSSS\nSS\nSSS',
    maxMeleeRange: 2,
  },
  {
    name: 'Deepborn Dire Troll',
    type: 'Warbeast',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/DeepbornDireTroll.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    maxMeleeRange: 2,
  },
  {
    name: 'Great Old One',
    type: 'Warbeast',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/GreatOldOne.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    maxMeleeRange: 1,
  },
  {
    name: 'Reef Troll',
    type: 'Warbeast',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/ReefTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    maxMeleeRange: 1,
  },
  {
    name: 'Booty Boss',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/BootyBoss.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'First Mate Hargor Stormjaw',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/FirstMateHargorStormjaw.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Mr. Bogs',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/MrBogs.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Sky Bomber',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/SkyBomber.jpg',
    size: '120mm',
    health: 28,
    maxMeleeRange: 1,
  },
  {
    name: 'Sky Raider',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/SkyBomber.jpg',
    size: '120mm',
    health: 28,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Battle Brig',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/PygBattleBrig.jpg',
    size: '80mm',
    health: 20,
    maxMeleeRange: 0,
  },
  {
    name: 'Pyg Cannon Crew',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/PygCannonCrew.jpg',
    size: '50mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Pyg Dirge',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/PygDirge.jpg',
    size: '50mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Sully',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/Sully.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Surgeon',
    type: 'Solo',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/Surgeon.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Lochabash Brothers',
    type: 'Unit',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/Durgul.jpg',
    size: '40mm',
    health: 10,
    alias: 'Durgul',
    includes: [
      {
        name: 'Konor',
        avatar: '/Warmahordes/avatars/Konor.jpg',
        size: '40mm',
        health: 10,
        maxMeleeRange: 2,
      },
      {
        name: 'Makabur',
        avatar: '/Warmahordes/avatars/Makabur.jpg',
        size: '40mm',
        health: 10,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Marauder Crew',
    type: 'Unit',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/MarauderCrew.jpg',
    size: '40mm',
    health: 5,
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Boarding Party',
    type: 'Unit',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/PygBoardingParty.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Galley Crew',
    type: 'Unit',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/PygGalleyCrew.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Shockers',
    type: 'Unit',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/PygShockers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Marauder Crew Bosun',
    type: 'Command Attachment',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/MarauderCrewBosun.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Marauder Crew Quartermaster',
    type: 'Command Attachment',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/MarauderCrewQuartermaster.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Marauder Crew Tapper',
    type: 'Command Attachment',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/MarauderCrewTapper.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Pyg Coxswain',
    type: 'Command Attachment',
    faction: 'Brineblood Marauders',
    avatar: '/Warmahordes/avatars/PygCoxswain.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Agathon, The Voice in the Darkness',
    type: 'Infernal Master',
    faction: 'Infernals',
    size: '50mm',
    avatar: '/Warmahordes/avatars/Agathon1.jpg',
    health: 17,
    essence: 9,
    maxMeleeRange: 1,
  },
  {
    name: 'Omodamos, The Black Gate',
    type: 'Infernal Master',
    faction: 'Infernals',
    size: '50mm',
    avatar: '/Warmahordes/avatars/Omodamos1.jpg',
    health: 19,
    essence: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Zaateroth, The Weaver of Shadows',
    type: 'Infernal Master',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Zaateroth1.jpg',
    size: '50mm',
    health: 16,
    essence: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Desolator',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Desolator.jpg',
    size: '50mm',
    damageGrid: 'OOOOOO\nOOOOOO\nMMMMMM\nMM\nIIII',
    essence: 1,
    maxMeleeRange: 1,
  },
  {
    name: 'Foreboder',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Foreboder.jpg',
    size: '30mm',
    damageGrid: 'OOOOOO\nO\nMMMMM\nII',
    essence: 1,
    maxMeleeRange: 1,
  },
  {
    name: 'Guardian of Souls',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/GuardianOfSouls.jpg',
    size: '120mm',
    damageGrid:
      'OOOOOOOOOO\nOOOOOOOOOO\nOOOOOOOOOO\nMMMMMMMM\nMMMMMMMM\nIIIIIIIIII',
    essence: 1,
    maxMeleeRange: 2,
  },
  {
    name: 'Harvester of Souls',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/HarvesterOfSouls.jpg',
    size: '120mm',
    damageGrid:
      'OOOOOOOOOO\nOOOOOOOOOO\nOOOOOOOOOO\nMMMMMMMM\nMMMMMMMM\nIIIIIIIIII',
    essence: 1,
    maxMeleeRange: 2,
  },
  {
    name: 'Lamenter',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Lamenter.jpg',
    size: '40mm',
    damageGrid: 'OOOOOO\nOOO\nMMMMM\nIIII',
    essence: 1,
    maxMeleeRange: 1,
  },
  {
    name: 'Shrieker',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Shrieker.jpg',
    size: '40mm',
    damageGrid: 'OOOOOO\nOOO\nMMMMM\nIIII',
    essence: 1,
    maxMeleeRange: 1,
  },
  {
    name: 'Soul Stalker',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/SoulStalker.jpg',
    size: '50mm',
    damageGrid: 'OOOOOO\nOOOOO\nMMMMMM\nM\nIIII',
    essence: 1,
    maxMeleeRange: 2,
  },
  {
    name: 'Tormentor',
    type: 'Horror',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Tormentor.jpg',
    size: '50mm',
    damageGrid: 'OOOOOO\nOOOOOO\nMMMMMM\nMM\nIIII',
    essence: 1,
    maxMeleeRange: 1,
  },
  {
    name: 'Cultist Band',
    type: 'Unit',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/CultistBand.jpg',
    size: '30mm',
    count: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Dark Sentinels',
    type: 'Unit',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/DarkSentinel.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 2,
    health: 5,
  },
  {
    name: 'Griever Swarm',
    type: 'Unit',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/GrieverSwarm.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Howlers',
    type: 'Unit',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Howlers.jpg',
    size: '40mm',
    count: 3,
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Master Preceptor Orin Midwinter',
    type: 'Command Attachment',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Midwinter2.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Alain Runewood, Lord of Ash',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Runewood2.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Eilish Garrity, the Dark Seeker',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Eilish2.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Great Princess Regna Gravnoy',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/PrincessGravnoy1.jpg',
    size: '30mm',
    health: 5,
    essence: 5,
    maxMeleeRange: 1,
  },
  {
    name: "Lord Roget d'Vyaros",
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Dvyaros1.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Lynda the Forgotten',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/LyndaTheForgotten.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Nicia, Hound of the Abyss',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Nicia2.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'The Wretch',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Wretch.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Umbral Guardian',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/UmbralGuardian.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Valin Hauke, The Fallen Knight',
    type: 'Solo',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/Hauke1.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Infernal Gate',
    type: 'Structure',
    faction: 'Infernals',
    avatar: '/Warmahordes/avatars/InfernalGate.jpg',
    size: '120mm',
    health: 25,
  },
  {
    name: 'Aurum Adeptus Syvestro',
    type: 'Warcaster',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/AurumAdeptusSyvestro.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Aurum Legate Lukas di Morray',
    type: 'Warcaster',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/AurumLegateLukasdiMorray.jpg',
    size: '30mm',
    health: 18,
    focus: 5,
    maxMeleeRange: 2,
    resource3Label: 'Juice',
    resource3: 0,
  },
  {
    name: 'Aurum Lucanum Athanor Locke',
    type: 'Warcaster',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/AurumLucanumAthanorLocke.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Catalytic',
  },
  {
    name: 'Captain Eira Mackay',
    type: 'Warcaster',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CaptainEiraMackay.jpg',
    size: '120mm',
    health: 30,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Major Aline Bennet',
    type: 'Warcaster',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/MajorAlineBennet.jpg',
    size: '40mm',
    health: 16,
    focus: 6,
  },
  {
    name: 'Marshal General Baldwin Gearhart & Mr. Clogg',
    type: 'Warcaster Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/MarshalGeneralBaldwinGearhartMrClogg.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    alias: 'Marshal General Baldwin Gearhart',
    includes: [
      {
        name: 'Mr. Clogg',
        avatar: '/Warmahordes/avatars/MrClogg.jpg',
        size: '30mm',
        health: 5,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Liberator',
    type: 'Warjack',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/Liberator.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##AMM\n##ACC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Retaliator',
    type: 'Warjack',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/Retaliator.jpg',
    size: '40mm',
    damageGrid: '##L-\n##LLM\n###MM\n###CC\n##RRC\n##R-',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Suppressor',
    type: 'Warjack',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/Suppressor.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Vindicator',
    type: 'Warjack',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/Vindicator.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Vulcan',
    type: 'Warjack',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/Vulcan.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n##SSCM\n##SCCM\n##SCCM\n##SSCM\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Combat Alchemists',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CombatAlchemists.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Containment Operatives',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/ContainmentOperatives.jpg',
    size: '30mm',
    count: 3,
  },
  {
    name: 'Crucible Guard Assault Troopers',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CrucibleGuardAssaultTroopers.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Crucible Guard Infantry',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CrucibleGuardInfantry.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Crucible Guard Infantry Officer & Standard',
    type: 'Command Attachment',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CrucibleGuardInfantryOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Crucible Guard Infantry Officer',
    includes: [
      {
        name: 'Crucible Guard Infantry Standard Bearer',
        avatar: '/Warmahordes/avatars/CrucibleGuardInfantryOfficerStandard.jpg',
        size: '30mm',
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Crucible Guard Rocketman Captain',
    type: 'Command Attachment',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CrucibleGuardRocketmanCaptain.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Crucible Guard Rocketmen',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CrucibleGuardRocketmen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Crucible Guard Storm Troopers',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CrucibleGuardStormTroopers.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Doctor Alejandro Mosby',
    type: 'Command Attachment',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/DoctorAlejandroMosby.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: "Dragon's Breath Rocket",
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/DragonsBreathRocket.jpg',
    size: '50mm',
    alias: "Dragon's Breath Rocket Gunner",
    includes: [
      {
        name: "Dragon's Breath Rocket Grunt",
        avatar: '/Warmahordes/avatars/DragonsBreathRocketGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Failed Experiments',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/FailedExperiments.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Rocketman Gunner',
    type: 'Unit',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/RocketmanGunner.jpg',
    size: '30mm',
    maxMeleeRange: 1,
    count: 3,
  },
  {
    name: 'Aurum Ominus Alyce Marc',
    type: 'Warcaster Attachment',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/AurumOminusAlyceMarc.jpg',
    size: '30mm',
    health: 5,
    includes: [
      {
        name: 'Aurum Ominus Alyce Marc (Big Alyce)',
        avatar: '/Warmahordes/avatars/AurumOminusAlyceMarcBigAlyce.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Ascendant Mentalist',
    type: 'Solo',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/AscendantMentalist.jpg',
    size: '40mm',
    maxMeleeRange: 2,
    health: 5,
  },
  {
    name: 'Crucible Guard Mechanik',
    type: 'Solo',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/CrucibleGuardMechanik.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Prospero',
    type: 'Solo',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/Prospero.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
    focus: 4,
  },
  {
    name: 'Rocketman Ace',
    type: 'Solo',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/RocketmanAce.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Rocketman Stinger',
    type: 'Solo',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/RocketmanStinger.jpg',
    size: '30mm',
    maxMeleeRange: 1,
    health: 5,
  },
  {
    name: 'Trancer',
    type: 'Solo',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/Trancer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Railless Interceptor',
    type: 'Battle Engine',
    faction: 'Crucible Guard',
    avatar: '/Warmahordes/avatars/RaillessInterceptor.jpg',
    size: '120mm',
    health: 30,
    maxMeleeRange: 1,
  },
  {
    name: 'The Child',
    type: 'Warlock',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/TheChild.jpg',
    size: '50mm',
    health: 18,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'The Dreamer',
    type: 'Warlock',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/TheDreamer.jpg',
    size: '30mm',
    health: 15,
    fury: 7,
    includes: [
      {
        name: 'Small Phantasm',
        avatar: '/Warmahordes/avatars/SmallPhantasm.jpg',
        size: '30mm',
        health: 5,
      },
      {
        name: 'Medium Phantasm',
        avatar: '/Warmahordes/avatars/MediumPhantasm.jpg',
        size: '40mm',
        health: 5,
      },
      {
        name: 'Large Phantasm',
        avatar: '/Warmahordes/avatars/LargePhantasm.jpg',
        size: '50mm',
        health: 5,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'The Heretic',
    type: 'Warlock',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/TheHeretic.jpg',
    size: '40mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'The King of Nothing',
    type: 'Warlock',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/TheKingofNothing.jpg',
    size: '30mm',
    health: 14,
    fury: 8,
  },
  {
    name: 'The Wanderer',
    type: 'Warlock',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/TheWanderer.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    includes: [
      {
        name: 'Road Marker',
        size: '30mm',
        count: 3,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Zevanna Agha, the Fate Keeper',
    type: 'Warlock',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/ZevannaAghatheFateKeeper.jpg',
    size: '120mm',
    health: 20,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Cage Rager',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/CageRager.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Clockatrice',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Clockatrice.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBB\nBB\nBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Crabbits',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Crabbit.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nBB\nBB\nB\nS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
    alias: 'Crabbit',
  },
  {
    name: 'Frightmare',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Frightmare.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Gorehound',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Gorehound.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Rattler',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Rattler.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Skin & Moans',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/SkinMoans.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Slaughterhouse',
    type: 'Warbeast',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Slaughterhouse.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMMM\nMM\nMMMMM\nBBBBBBBBB\nBB\nBBBBBBBBB\nSSSSSSSSSSS\nSS\nSSSSSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Dread Rots',
    type: 'Unit',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/DreadRots.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Hollow Holden',
    type: 'Command Attachment',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/HollowHolden.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Hollowmen',
    type: 'Unit',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Hollowmen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Lantern Man',
    type: 'Command Attachment',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/LanternMan.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Mad Caps',
    type: 'Unit',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/MadCapBoss.jpg',
    size: '50mm',
    health: 5,
    includes: [
      {
        name: 'Mad Cap Grunt',
        avatar: '/Warmahordes/avatars/MadCapGrunt.jpg',
        size: '30mm',
        count: 2,
      },
    ],
  },
  {
    name: 'Malady Man',
    type: 'Unit',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/MaladyMan.jpg',
    size: '50mm',
    health: 5,
    includes: [
      {
        name: 'Monkey',
        avatar: '/Warmahordes/avatars/MaladyManMonkey.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Murder Crows',
    type: 'Unit',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/MurderCrows.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Neigh Slayer War Horse',
    type: 'Command Attachment',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/NeighSlayerWarHorseCommandAttachment.jpg',
    size: '50mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Neigh Slayers',
    type: 'Unit',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/NeighSlayers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Piggyback Officer',
    type: 'Command Attachment',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/PiggybackOfficerCommandAttachment.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Piggybacks',
    type: 'Unit',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Piggybacks.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Twilight Sisters',
    type: 'Unit',
    alias: 'Heidrun',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Heidrun.jpg',
    size: '30mm',
    health: 5,
    includes: [
      {
        name: 'Agrona',
        avatar: '/Warmahordes/avatars/Agrona.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Baron Tonguelick, Lord of Warts',
    type: 'Warlock Attachment',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/BaronTonguelickLordofWarts.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 3,
  },
  {
    name: 'Cask Imp',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/CaskImp.jpg',
    size: '30mm',
    count: 2,
  },
  {
    name: 'Defiled Archon',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/DefiledArchon.jpg',
    size: '50mm',
    health: 13,
    maxMeleeRange: 2,
  },
  {
    name: 'The Four Horseymans',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/TheFourHorseymans.jpg',
    size: '50mm',
    health: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Glimmer Imp',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/GlimmerImp.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Grave Ghoul',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/GraveGhoul.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Isaiah, Dread Harvester',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/IsaiahDreadHarvester.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Isaiah, Dread Harvester (Dismount)',
        avatar: '/Warmahordes/avatars/IsaiahDreadHarvesterDismount.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Lady Karianna Rose',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/LadyKariannaRose.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Lord Longfellow',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/LordLongfellow.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trapperkin',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Trapperkin.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    includes: [
      {
        name: 'Trapdoor Marker',
        size: '30mm',
      },
    ],
  },
  {
    name: 'Witchwood',
    type: 'Solo',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/Witchwood.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Death Knell',
    type: 'Battle Engine',
    faction: 'Grymkin',
    avatar: '/Warmahordes/avatars/DeathKnell.jpg',
    size: '120mm',
    health: 22,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Artificer General Nemo',
    type: 'Warcaster Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/ArtificerGeneralNemoStormChaserFinch.jpg',
    size: '30mm',
    health: 15,
    focus: 7,
    includes: [
      {
        name: 'Storm Chaser Finch',
        avatar: '/Warmahordes/avatars/StormChaserFinch.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Allison Jakes',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainAllisonJakes.jpg',
    size: '30mm',
    health: 15,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Allister Caine',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainAllisterCaine.jpg',
    size: '30mm',
    health: 15,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Athena di Baro',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainAthenaDiBaro.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Captain Buck Hasker',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainBuckHasker.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain E. Dominic Darius & Halfjacks',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainEDominicDariusHalfjacks.jpg',
    size: '40mm',
    health: 22,
    focus: 6,
    alias: 'Captain E. Dominic Darius',
    includes: [
      {
        name: 'Halfjack',
        avatar: '/Warmahordes/avatars/Halfjack.jpg',
        size: '30mm',
        count: 3,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Jeremiah Kraye',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainJeremiahKraye.jpg',
    size: '50mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Kara Sloan',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainKaraSloan.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Madison Calder',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainMadisonCalder.jpg',
    size: '50mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Raef Huxley',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainRaefHuxley.jpg',
    size: '50mm',
    health: 24,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Victoria Haley',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainVictoriaHaley.jpg',
    size: '30mm',
    health: 15,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Colonel Markus "Siege" Brisbane',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/ColonelMarkusSiegeBrisbane.jpg',
    size: '40mm',
    health: 18,
    focus: 6,
    includes: [
      {
        name: 'Rocket Turret',
        avatar: '/Warmahordes/avatars/RocketTurret.jpg',
        size: '40mm',
        health: 5,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Commander Adept Nemo',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CommanderAdeptNemo.jpg',
    size: '30mm',
    health: 14,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Commander Coleman Stryker',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CommanderColemanStryker.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Commander Dalin Sturgis',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CommanderDalinSturgis.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'General Adept Nemo',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/GeneralAdeptNemo.jpg',
    size: '30mm',
    health: 14,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Lieutenant Allister Caine',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LieutenantAllisterCaine.jpg',
    size: '30mm',
    health: 15,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Lord Commander Stryker',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LordCommanderStryker.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Lord General Coleman Stryker',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LordGeneralColemanStryker.jpg',
    size: '50mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Major Allister Caine',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MajorAllisterCaine.jpg',
    size: '30mm',
    health: 15,
    focus: 6,
    maxMeleeRange: 0,
  },
  {
    name: 'Major Anson Wolfe',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MajorAnsonWolfe.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Mechanik Adept Sparkhammer',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MechanikAdeptSparkhammer.jpg',
    size: '30mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Major Beth Maddox',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MajorBethMaddox.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Major Markus "Siege" Brisbane',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MajorMarkusSiegeBrisbane.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Major Prime Victoria Haley',
    type: 'Warcaster Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MajorPrimeVictoriaHaley.jpg',
    size: '30mm',
    health: 15,
    focus: 8,
    includes: [
      {
        name: 'Haley Past',
        avatar: '/Warmahordes/avatars/HaleyPast.jpg',
        size: '30mm',
        health: 5,
        focus: 4,
        maxMeleeRange: 2,
      },
      {
        name: 'Haley Future',
        avatar: '/Warmahordes/avatars/HaleyFuture.jpg',
        size: '30mm',
        health: 5,
        focus: 4,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Major Victoria Haley',
    type: 'Warcaster',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MajorVictoriaHaley.jpg',
    size: '30mm',
    health: 15,
    focus: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Ace',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Ace.jpg',
    size: '40mm',
    damageGrid: '##L\n#LLM\n###MM\n###CC\n#RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Avenger',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Avenger.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Borealis',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Borealis.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n###HMM\n###HCC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Brickhouse',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Brickhouse.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Centurion',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Centurion.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Charger',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Charger.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Courser',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Courser.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##HMM\n##HCC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Cyclone',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Cyclone.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Defender',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Defender.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Deuce',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Deuce.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n###HMM\n###HCC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dynamo',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Dynamo.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Firefly',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Firefly.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Grenadier',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Grenadier.jpg',
    size: '40mm',
    damageGrid: '##L\n#LLM\n###MM\n###CC\n#RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Hammersmith',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Hammersmith.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Hunter',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Hunter.jpg',
    size: '40mm',
    damageGrid: '##L\n#LLM\n###MM\n###CC\n#RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Hurricane',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Hurricane.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n#AACM\n##ACCM\n##ACCM\n#AACM\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Ironclad',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Ironclad.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Lancer',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Lancer.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##AMM\n##ACC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Maelstrom',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Maelstrom.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n#SSMH\n##SCHH\n##SCHH\n#SSMH\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 3,
  },
  {
    name: 'Minuteman',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Minuteman.jpg',
    size: '40mm',
    damageGrid: '##L\n#LLM\n###MM\n###CC\n#RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: "Ol' Rowdy",
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/OlRowdy.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
    alias: 'Ol’ Rowdy',
  },
  {
    name: 'Plugger',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Plugger.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n##SHMM\n##SHCC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Reliant',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Reliant.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Sentinel',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Sentinel.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Stormclad',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Stormclad.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Stormwall',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Stormwall.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n#SSCM\n##SCCM\n##SCCM\n#SSCM\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    includes: [
      {
        name: 'Lightning Pod',
        avatar: '/Warmahordes/avatars/LightningPod.jpg',
        size: '30mm',
      },
    ],
    maxMeleeRange: 2,
    resource3Label: 'Lightning Pods',
    resource3: 3,
  },
  {
    name: 'Thorn',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Thorn.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##AMM\n##ACC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Stryker',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Stryker.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n###HMM\n###HCC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'The General',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TheGeneral.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n###HMM\n###HCC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Thunderhead',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Thunderhead.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Triumph',
    type: 'Warjack',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Triumph.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Arcane Tempest Gun Mage Officer',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/ArcaneTempestGunMageOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Arcane Mechaniks',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/ArcaneMechanik.jpg',
    size: '40mm',
    count: 3,
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Arcane Tempest Gun Mages',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/ArcaneTempestGunMages.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Black 13th Strike Force',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Lynch.jpg',
    size: '30mm',
    health: 5,
    alias: 'Lynch',
    includes: [
      {
        name: 'Ryan',
        avatar: '/Warmahordes/avatars/Ryan.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
      {
        name: 'Watts',
        avatar: '/Warmahordes/avatars/Watts.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Jonas Murdoch',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainJonasMurdoch.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Field Mechaniks',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/FieldMechanikCrewChief.jpg',
    size: '30mm',
    alias: 'Crew Chief',
    includes: [
      {
        name: 'Gobber Grunt',
        avatar: '/Warmahordes/avatars/FieldMechanikGobberGrunt.jpg',
        size: '30mm',
        count: 3,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Legionnaire Standard Bearer',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LegionnaireStandardBearer.jpg',
    size: '30mm',
    maxMeleeRange: 2,
  },
  {
    name: 'Long Gunner Infantry',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LongGunnerInfantry.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Long Gunner Infantry Officer & Standard',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LongGunnerInfantryOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Long Gunner Infantry Officer',
    includes: [
      {
        name: 'Long Gunner Infantry Standard Bearer',
        avatar: '/Warmahordes/avatars/LongGunnerInfantryOfficerStandard.jpg',
        size: '30mm',
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Rangers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Rangers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Silver Line Stormguard',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/SilverLineStormguard.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Storm Callers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormCallers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Storm Lances',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormLances.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Storm Vanes',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormVanes.jpg',
    size: '30mm',
    health: 5,
    count: 3,
    maxMeleeRange: 0,
  },
  {
    name: 'Stormblade Infantry',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormbladeInfantry.jpg',
    size: '30mm',
    maxMeleeRange: 2,
    alias: 'Stormblade Infantry Leader',
    includes: [
      {
        name: 'Stormblade Infantry',
        avatar: '/Warmahordes/avatars/StormbladeInfantry.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Stormblade Infantry Officer & Standard',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormbladeInfantryOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Stormblade Infantry Officer',
    includes: [
      {
        name: 'Stormblade Infantry Standard',
        avatar: '/Warmahordes/avatars/StormbladeInfantryOfficerStandard.jpg',
        size: '30mm',
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Stormblade Infantry Storm Gunner',
    type: 'Weapon Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormbladeInfantryStormGunner.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Stormblade Legionnaires',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormbladeLegionnaires.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Stormguard Infantry',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormguardInfantry.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Stormguard Legionnaires',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormguardLegionnaires.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Stormsmith Grenadiers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormsmithGrenadiers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Stormsmith Storm Tower',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormTowerGunner.jpg',
    size: '50mm',
    includes: [
      {
        name: 'Stormsmith Storm Tower Grunt',
        avatar: '/Warmahordes/avatars/StormTowerGrunt.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Stormthrower Legionnaires',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormthrowerLegionnaires.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Sword Knight Officer & Standard',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/SwordKnightOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Sword Knight Officer',
    includes: [
      {
        name: 'Sword Knight Standard',
        avatar: '/Warmahordes/avatars/SwordKnightOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Sword Knights',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/SwordKnights.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Storm Lance Legionnaires',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormLanceLegionnaires.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Tempest Assailers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TempestAssailers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Tempest Blazers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TempestBlazers.jpg',
    size: '50mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Tempest Thunderers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TempestThunderers.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'The Black 13th',
    type: 'Unit',
    faction: 'Cygnar',
    alias: 'Ryan 3',
    avatar: '/Warmahordes/avatars/Ryan3.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
    includes: [
      {
        name: 'Watts 3',
        avatar: '/Warmahordes/avatars/Watts3.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
      {
        name: 'Glover 1',
        avatar: '/Warmahordes/avatars/Glover1.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'The Rowdies',
    type: 'Unit',
    faction: 'Cygnar',
    alias: 'Dalton',
    avatar: '/Warmahordes/avatars/Dalton.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
    includes: [
      {
        name: 'Franks',
        avatar: '/Warmahordes/avatars/Franks.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
      {
        name: 'Jenkins',
        avatar: '/Warmahordes/avatars/Jenkins.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Trencher Cannon Crew',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherCannonCrewGunner.jpg',
    size: '50mm',
    alias: 'Trencher Cannon',
    includes: [
      {
        name: 'Trencher Cannon Crew Grunt',
        avatar: '/Warmahordes/avatars/TrencherCannonCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Chain Gun Crew',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherChainGunCrew.jpg',
    size: '50mm',
    alias: 'Trench Chain Gun',
    includes: [
      {
        name: 'Trencher Chain Gun Crew Grunt',
        avatar: '/Warmahordes/avatars/TrencherChainGunCrewGrunt.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Combat Engineers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherCombatEngineers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Commando Officer',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherCommandoOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Commando Scattergunners',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherCommandoScattergunner.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Commandos',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherCommandos.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Express Team',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherExpressTeamSpotter.jpg',
    size: '30mm',
    alias: 'Trencher Express Team Spotter',
    includes: [
      {
        name: 'Trencher Express Team Gunner',
        avatar: '/Warmahordes/avatars/TrencherExpressTeamGunner.jpg',
        size: '40mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Karli',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainKarli.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Infantry',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherInfantry.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Trencher Infantry Officer',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherInfantryOfficerSniper.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Trencher Rifle Grenadiers',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherInfantryRifleGrenadier.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Long Gunner Officer',
    type: 'Command Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherLongGunnerOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Long Gunners',
    type: 'Unit',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherLongGunners.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Arcane Tempest Rifleman',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/ArcaneTempestRifleman.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Archduke Alain Runewood, Lord of Fharin',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/ArchdukeAlainRunewoodLordofFharin.jpg',
    size: '30mm',
    health: 5,
    alias: 'Runewood1',
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Arlan Strangewayes',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainArlanStrangewayes.jpg',
    size: '30mm',
    health: 8,
    alias: 'Strangeways1',
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Bastian Falk',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainBastianFalk.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Maxwell Finn',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CaptainMaxwellFinn.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Commander Anson Hitch',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/CommanderAnsonHitch.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Gun Mage Captain Adept',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/GunMageCaptainAdept.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Lieutenant Sara Brisbane',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LieutenantSaraBrisbane.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Journeyman Lieutenant Allister Caine',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/JourneymanLieutenantAllisterCaine.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Journeyman Warcaster',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/JourneymanWarcaster.jpg',
    size: '30mm',
    health: 5,
    focus: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Legionnaire Officer',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LegionnaireOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Lieutenant Allison Jakes',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LieutenantAllisonJakes.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Lieutenant Bastian Falk',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LieutenantBastianFalk.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Lieutenant Gwen Keller',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/LieutenantGwenKeller.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Major Katherine Laddermore',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/MajorKatherineLaddermore.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Major Katherine Laddermore (Dismount)',
        avatar: '/Warmahordes/avatars/MajorKatherineLaddermore.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Patrol Dog',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/PatrolDog.jpg',
    size: '30mm',
  },
  {
    name: 'Sergeant Brunaugh "Bruno" Ramjaw',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/BrunoRamjaw.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 0,
  },
  {
    name: 'Sir Dreyfus the Storm Knight',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/SirDreyfustheStormKnight.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Squire',
    type: 'Warcaster Attachment',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Squire.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Sharpshooter',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Sharpshooter.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Specialist Tyson Vas',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/SpecialistTysonVas.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Stormblade Captain',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormbladeCaptain.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Stormsmith Stormcaller',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormsmithStormcaller.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Trench Buster',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrenchBuster.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Trencher Infantry Sniper',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherInfantryOfficerSniper.jpg',
    size: '30mm',
    maxMeleeRange: 2,
  },
  {
    name: 'Trencher Master Gunner',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherMasterGunner.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trencher Warcaster Lieutenant',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherWarcasterLieutenant.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Weather Station',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/WeatherStation.jpg',
    size: '50mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Zephyr',
    type: 'Solo',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/Zephyr.jpg',
    size: '80mm',
    health: 18,
    maxMeleeRange: 1,
  },
  {
    name: 'Storm Strider',
    type: 'Battle Engine',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/StormStrider.jpg',
    size: '120mm',
    health: 28,
    maxMeleeRange: 1,
    resource3Label: 'Power',
    resource3: 0,
  },
  {
    name: 'Trencher Blockhouse',
    type: 'Structure',
    faction: 'Cygnar',
    avatar: '/Warmahordes/avatars/TrencherBlockhouse.jpg',
    size: '120mm',
    health: 30,
  },
  {
    name: 'Anson Durst, Rock of the Faith',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/AnsonDurstRockoftheFaith.jpg',
    size: '40mm',
    health: 19,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Feora, the Forsaken',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FeoraTheForsaken.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Feora, Priestess of the Flame',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FeoraPriestessoftheFlame.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Feora, Protector of the Flame',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FeoraProtectoroftheFlame.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Feora, the Conquering Flame',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FeoratheConqueringFlame.jpg',
    size: '50mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Grand Exemplar Kreoss',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/GrandExemplarKreoss.jpg',
    size: '30mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Grand Scrutator Severius',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/GrandScrutatorSeverius.jpg',
    size: '30mm',
    health: 16,
    focus: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Hierarch Severius',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HierarchSeverius.jpg',
    size: '30mm',
    health: 16,
    focus: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'High Allegiant Amon Ad-Raza',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HighAllegiantAmonAdRaza.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Synergy',
  },
  {
    name: 'High Executioner Servath Reznik',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HighExecutionerServathReznik.jpg',
    size: '40mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'High Exemplar Cyrenia',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HighExemplarCyrenia.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'High Exemplar Kreoss',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HighExemplarKreoss.jpg',
    size: '30mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Intercessor Kreoss',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/IntercessorKreoss.jpg',
    size: '50mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Malekus, the Burning Truth',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/MalekustheBurningTruth.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Servath Reznik, Wrath of Ages',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ServathReznikWrathofAges.jpg',
    size: '120mm',
    health: 24,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Sovereign Tristan Durant',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/SovereignTristanDurant.jpg',
    size: '30mm',
    health: 15,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Testament of Menoth',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/TestamentofMenoth.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'The Harbinger of Menoth',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/TheHarbingerofMenoth.jpg',
    size: '50mm',
    health: 17,
    focus: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'The High Reclaimer',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/TheHighReclaimer.jpg',
    size: '30mm',
    health: 18,
    focus: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Thyra, Flame of Sorrow',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ThyraFlameofSorrow.jpg',
    size: '30mm',
    health: 15,
    focus: 6,
    maxMeleeRange: 1,
    resource3Label: 'Power',
  },
  {
    name: 'Vice Scrutator Vindictus',
    type: 'Warcaster',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ViceScrutatorVindictus.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Avatar of Menoth',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/AvatarofMenoth.jpg',
    size: '50mm',
    damageGrid: '###L\n####LL\n####MM\n#####M\n####RR\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Blessing of Vengeance',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/BlessingofVengeance.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##AMM\n##ACC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Blood of Martyrs',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/BloodofMartyrs.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Castigator',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Castigator.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Crusader',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Crusader.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dervish',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Dervish.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Devout',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Devout.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Eye of Truth',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/EyeofTruth.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n###HMM\n###HCC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Fire of Salvation',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FireofSalvation.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Guardian',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Guardian.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n###AMM\n###ACC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Hand of Judgment',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HandofJudgment.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Indictor',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Indictor.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Judicator',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Judicator.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n##LCM\n##LCM\n##SSCM\n##SCCM\n##SCCM\n##SSCM\n##RCM\n##RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Purifier',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Purifier.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Reckoner',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Reckoner.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Redeemer',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Redeemer.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Repenter',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Repenter.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Revelator',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Revelator.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n##LCM\n##LCM\n##SSCM\n##SCCM\n##SCCM\n##SSCM\n##RCM\n##RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Revenger',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Revenger.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##AMM\n##ACC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Sanctifier',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Sanctifier.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Scourge of Heresy',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ScourgeofHeresy.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Templar',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Templar.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Vanquisher',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Vanquisher.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Vigilant',
    type: 'Warjack',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Vigilant.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Attendant Priest',
    type: 'Command Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/AttendantPriest.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Choir of Menoth',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ChoirofMenoth.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Daughters of the Flame',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/DaughtersoftheFlame.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Deliverer Skyhammers',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/DelivererSkyhammers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Deliverer Sunburst Crew',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/DelivererSunburstCrewGunner.jpg',
    size: '50mm',
    alias: 'Deliverer Sunburst Crew Gunner',
    includes: [
      {
        name: 'Deliverer Sunburst Crew Grunt',
        avatar: '/Warmahordes/avatars/DelivererSunburstCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Exemplar Bastions',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarBastions.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Exemplar Cinerator Officer',
    type: 'Command Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarCineratorOfficer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Exemplar Cinerators',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarCinerators.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Exemplar Errant Officer & Standard',
    type: 'Command Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarErrantOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Exemplar Errant Officer',
    includes: [
      {
        name: 'Exemplar Errant Standard',
        avatar: '/Warmahordes/avatars/ExemplarErrantOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Exemplar Errants',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarErrants.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Exemplar Vengers',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarVengers.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Flame Bringers',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FlameBringers.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Flameguard Cleanser Officer',
    type: 'Command Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FlameguardCleanserOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Flameguard Cleansers',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/FlameguardCleansers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Holy Zealots',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HolyZealots.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Initiates of the Wall',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/InitiatesoftheOrderoftheWall.jpg',
    size: '30mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Knights Exemplar',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/KnightsExemplar.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Knights Exemplar Officer',
    type: 'Command Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/KnightsExemplarOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Monolith Bearer',
    type: 'Command Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/MonolithBearer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Temple Flameguard',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/TempleFlameguard.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Temple Flameguard Officer & Standard',
    type: 'Command Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/TempleFlameguardOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Temple Flameguard Officer',
    includes: [
      {
        name: 'Temple Flameguard Standard',
        avatar: '/Warmahordes/avatars/TempleFlameguardOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Visgoth Juviah Rhoven & Honor Guard',
    type: 'Unit',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Rhoven.jpg',
    size: '30mm',
    health: 5,
    alias: 'Rhoven1',
    includes: [
      {
        name: 'Gius1',
        avatar: '/Warmahordes/avatars/Gius.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 2,
      },
      {
        name: 'Cassius1',
        avatar: '/Warmahordes/avatars/Cassian.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Allegiant of the Order of the Fist',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/AllegiantoftheOrderoftheFist.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Champion of the Order of the Wall',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ChampionoftheOrderoftheWall.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Champion of the Order of the Wall (Dismount)',
        avatar: '/Warmahordes/avatars/ChampionoftheOrderoftheWall.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Deliverer Arms Master',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/DelivererArmsMaster.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Exemplar Bastion Seneschal',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarBastionSeneschal.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Exemplar Errant Seneschal',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarErrantSeneschal.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Exemplar Warder',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarWarder.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Exemplar Warder Elias Gade',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ExemplarWarderEliasGade.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Hand of Silence',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HandofSilence.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Hierophant',
    type: 'Warcaster Attachment',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Hierophant.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'High Exemplar Gravus',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HighExemplarGravus.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'High Exemplar Gravus (Dismount)',
        avatar: '/Warmahordes/avatars/HighExemplarGravus.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'High Paladin Dartan Vilmon',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/HighPaladinDartanVilmon.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Initiate Tristan Durant',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/InitiateTristanDurant.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Knights Exemplar Seneschal',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/KnightsExemplarSeneschal.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Nicia, Tear of Vengeance',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/NiciaTearofVengeance.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Paladin of the Order of the Wall',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/PaladinoftheOrderoftheWall.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyrrhus, Flameguard Hero',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/PyrrhusFlameguardHero.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Reclaimer Gatekeeper',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ReclaimerGatekeeper.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Scrutator Potentate Severius',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ScrutatorPotentateSeverius.jpg',
    size: '30mm',
    health: 5,
    focus: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'The Covenant of Menoth',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/TheCovenantofMenoth.jpg',
    size: '50mm',
    health: 5,
  },
  {
    name: 'The Living Covenant',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/LivingCovenant.jpg',
    size: '40mm',
    health: 5,
  },
  {
    name: 'Vassal Mechanik',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/VassalMechanik.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Vassal of Menoth',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/VassalofMenoth.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Wrack',
    type: 'Solo',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/Wrack.jpg',
    size: '40mm',
    focus: 1,
  },
  {
    name: 'Shrine of the Lawgiver',
    type: 'Structure',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/ShrineoftheLawgiver.jpg',
    size: '120mm',
    health: 28,
    resource3: 3,
    resource3Label: 'Souls',
  },
  {
    name: 'Vessel of Judgment',
    type: 'Battle Engine',
    faction: 'Protectorate of Menoth',
    avatar: '/Warmahordes/avatars/VesselofJudgment.jpg',
    size: '120mm',
    health: 32,
    maxMeleeRange: 1,
  },
  {
    name: 'Assault Kommander Strakhov & Kommandos',
    type: 'Warcaster Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/AssaultKommanderStrakhovKommandos.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    alias: 'Assault Kommander Strakhov',
    includes: [
      {
        name: 'Kommando',
        avatar: '/Warmahordes/avatars/Kommando.jpg',
        size: '30mm',
        health: 5,
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Forward Kommander Sorscha',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ForwardKommanderSorscha.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Kapitan Ilari Borisyuk',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KapitanIlariBorisyuk.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Kapitan Ekaterina Baranova',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KapitanEkaterinaBaranova.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Kapitan Yana Kovoskiy',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KapitanYanaKovoskiy.jpg',
    size: '50mm',
    health: 20,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Kapitan Zahara Vilkul',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KapitanZaharaVilkul.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Karchev & Deathjack, Malignant Fusion',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Karjack.jpg',
    size: '50mm',
    damageGrid: '###SL-\n###SLL\n####MM\n#####M\n###SRR\n###SR-',
    focus: 6,
    resource3: 2,
    resource3Label: 'Souls',
    maxMeleeRange: 2,
    alias: 'Karchev2',
  },
  {
    name: 'Karchev the Terrible',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KarchevtheTerrible.jpg',
    size: '50mm',
    damageGrid: '####L\n####LL\n####MM\n#####M\n####RR\n####R',
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Koldun Kommander Aleksandra Zerkova',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KoldunKommanderAleksandraZerkova.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Kommandant Irusk',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommandantIrusk.jpg',
    size: '30mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Kommandant Sorscha Kratikoff',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommandantSorschaKratikoff.jpg',
    size: '40mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Kommander Andrei Malakov',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderAndreiMalakov.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Kommander Harkevich, the Iron Wolf',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderHarkevichtheIronWolf.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Kommander Oleg Strakhov',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderOlegStrakhov.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Kommander Orsus Zoktavir',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderOrsusZoktavir.jpg',
    size: '40mm',
    health: 20,
    focus: 7,
    maxMeleeRange: 2,
    resource3Label: 'Kill Count',
    resource3: 0,
  },
  {
    name: 'Kommander Sorscha',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderSorscha.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Kommander Tatiana Sikora',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderTatianaSikora.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Kommander Valerii Savaryn',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderValeriiSavaryn.jpg',
    size: '30mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Kommander Zoktavir, the Butcher Unleashed',
    type: 'Warcaster Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommanderZoktavirtheButcherUnleashed.jpg',
    size: '40mm',
    health: 20,
    focus: 6,
    includes: [
      {
        name: 'War Argus',
        avatar: '/Warmahordes/avatars/WarArgus.jpg',
        size: '40mm',
        health: 8,
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Lord Kozlov, Viscount of Scarsgrad',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/LordKozlovViscountofScarsgrad.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Obavnik Kommander Zerkova & Reaver Guard',
    type: 'Warcaster Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ObavnikKommanderZerkovaReaverGuard.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    alias: 'Obavnik Kommander Zerkova',
    includes: [
      {
        name: 'Reaver Guard',
        avatar: '/Warmahordes/avatars/ReaverGuard.jpg',
        size: '30mm',
        health: 5,
        count: 2,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Orsus Zoktavir, The Butcher of Khardov',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/OrsusZoktavirTheButcherofKhardov.jpg',
    size: '40mm',
    health: 20,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Supreme Kommandant Irusk',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/SupremeKommandantIrusk.jpg',
    size: '30mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'The Old Witch of Khador',
    type: 'Warcaster Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/TheOldWitchofKhador.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    includes: [
      {
        name: 'Scrapjack',
        avatar: '/Warmahordes/avatars/Scrapjack.jpg',
        size: '40mm',
        damageGrid: '#M\n##CM\n###AC\n###AC\n##CM\n#M',
        focus: 0,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Vladimir Tzepesci, Great Prince of Umbrey',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/VladimirTzepesciGreatPrinceofUmbrey.jpg',
    size: '50mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
    resource3Label: 'Blood Quenched',
    resource3: 0,
  },
  {
    name: 'Vladimir Tzepesci, the Dark Champion',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/VladimirTzepescitheDarkChampion.jpg',
    size: '30mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Vladimir Tzepesci, the Dark Prince',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/VladimirTzepescitheDarkPrince.jpg',
    size: '30mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Zevanna Agha, the Fate Keeper',
    type: 'Warcaster',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ZevannaAghatheFateKeeper.jpg',
    size: '120mm',
    health: 20,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Avalanche',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Avalanche.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n###HMM\n###HCC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Beast 09',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Beast.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Behemoth',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Behemoth.jpg',
    size: '50mm',
    damageGrid: '####LL\n####LM\n###SMM\n###SCC\n####RC\n####RR',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Berserker',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Berserker.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####C\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Black Ivan',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/BlackIvan.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Conquest',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Conquest.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n#LLC\n##LCM\n###LCM\n##SSCM\n##SCCM\n##SCCM\n##SSCM\n###RCM\n##RCM\n#RRC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Decimator',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Decimator.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Demolisher',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Demolisher.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Destroyer',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Destroyer.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Devastator',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Devastator.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dire Wolf',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/DireWolf.png',
    size: '50mm',
    damageGrid: '###L\n##LLM\n###HMM\n###HCC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Drago',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Drago.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Grolar',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Grolar.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Juggernaut',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Juggernaut.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Great Bear',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/GreatBear.png',
    size: '50mm',
    damageGrid: '####L\n###LLM\n###HMM\n###HCC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Kodiak',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Kodiak.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Mad Dog',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/MadDog.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####C\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Marauder',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Marauder.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Mastodon',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Mastadon.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n#LLC\n##LCM\n###LCM\n##SSMH\n##SCHH\n##SCHH\n##SSMH\n###RCM\n##RCM\n#RRC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Medveditsa',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Medveditsa.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n###HMM\n###HCC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Rager',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Rager.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####C\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Razor',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Razor.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n###HMM\n###HCC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Ruin',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Ruin.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Spriggan',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Spriggan.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Torch',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Torch.jpg',
    size: '50mm',
    damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Victor',
    type: 'Warjack',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Victor.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n#LLC\n##LCM\n###LCM\n##SSCM\n##SCCM\n##SCCM\n##SSCM\n###RCM\n##RCM\n#RRC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Assault Kommando Flamethrowers',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/AssaultKommandoFlameThrower.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Arkanists',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Arkanists.jpg',
    size: '30mm',
    count: 3,
  },
  {
    name: 'Assault Kommandos',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/AssaultKommandos.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Battle Mechanik Officer',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/BattleMechanikOfficer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Battle Mechaniks',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/BattleMechaniks.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Black Dragon Officer & Standard',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/BlackDragonOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Black Dragon Officer',
    includes: [
      {
        name: 'Black Dragon Standard',
        avatar: '/Warmahordes/avatars/BlackDragonOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Black Dragons',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/BlackDragons.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Doom Reaver Swordsmen',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/DoomReaverSwordsmen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Great Bears of the Gallowswood',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Volkov.jpg',
    size: '30mm',
    health: 5,
    alias: 'Volkov1',
    includes: [
      {
        name: 'Kolsk1',
        avatar: '/Warmahordes/avatars/Kolsk.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
      {
        name: 'Yarovich1',
        avatar: '/Warmahordes/avatars/Yarovich.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Greylord Escort',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/GreylordEscort.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Greylord Outriders',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/GreylordOutriders.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Greylord Ternion',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/GreylordTernion.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Iron Fang Pikemen',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/IronFangPikemen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Iron Fang Pikemen Officer & Standard',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/IronFangPikemenOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Iron Fang Pikemen Officer',
    includes: [
      {
        name: 'Iron Fang Pikemen Standard',
        avatar: '/Warmahordes/avatars/IronFangPikemenOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Iron Fang Uhlans',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/IronFangUhlans.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Koldun Kapitan Valachev',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KoldunKapitanValachev.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Kommandant Atanas Arconovich & Standard',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KommandantAtanasArconovich.jpg',
    size: '40mm',
    health: 8,
    alias: 'Kommandant Atanas Arconovich',
    includes: [
      {
        name: 'Standard Bearer',
        avatar: '/Warmahordes/avatars/ArconovichStandard.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Kossite Woodsmen',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KossiteWoodsmen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Bombardier Officer',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarBombardierOfficer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Bombardiers',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarBombardiers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Demolition Korps',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarDemolitionCorps.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Man-O-War Shocktrooper Officer',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarShocktrooperOfficer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Man-O-War Shocktroopers',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarShocktroopers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Man-O-War Suppressors',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarSuppressors.jpg',
    size: '50mm',
    health: 10,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Wrecking Crew',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarWreckingCrew.jpg',
    size: '50mm',
    health: 10,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Sergeant Dragos Dragadovich',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/SergeantDragosDragadovich.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Shock Trooper Gunners',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ShockTrooperGunners.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Shock Trooper Pikemen',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ShockTrooperPikemen.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'The Hounds',
    type: 'Unit',
    faction: 'Khador',
    alias: 'Teterya 1',
    avatar: '/Warmahordes/avatars/Teterya1.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
    includes: [
      {
        name: 'Fedyniak 1',
        avatar: '/Warmahordes/avatars/Fedyniak1.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
      {
        name: 'Skrobala 1',
        avatar: '/Warmahordes/avatars/Skrobala1.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Widowmaker Scouts',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WidowmakerScouts.jpg',
    size: '30mm',
    count: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Field Gun Crew',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardFieldGunCrew.jpg',
    size: '50mm',
    alias: 'Winter Guard Field Gun Gunner',
    includes: [
      {
        name: 'Winter Guard Field Gun Crew Grunt',
        avatar: '/Warmahordes/avatars/WinterGuardFieldGunCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Infantry',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardInfantry.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Infantry Officer & Standard',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardInfantryOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Winter Guard Infantry Officer',
    includes: [
      {
        name: 'Winter Guard Infantry Standard',
        avatar: '/Warmahordes/avatars/WinterGuardInfantryOfficerStandard.jpg',
        size: '30mm',
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Mortar Crew',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardMortarCrew.jpg',
    size: '50mm',
    alias: 'Winter Guard Mortar Gunner',
    includes: [
      {
        name: 'Winter Guard Mortar Crew Grunt',
        avatar: '/Warmahordes/avatars/WinterGuardMortarCrewGrunt.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Rifle Corps',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardRifleCorps.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Rocketeers',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardRocketeer.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Korps Hunting Dog',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterKorpsHuntingDog.jpg',
    size: '40mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Korps Infantry',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterKorpsInfantry.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Korps Snipers',
    type: 'Unit',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterKorpsSharpshooters.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Korps Standard',
    type: 'Command Attachment',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterKorpsStandard.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'AC-2 Bison',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Bison.jpg',
    size: '80mm',
    health: 24,
    maxMeleeRange: 1,
  },
  {
    name: 'Battle Mechanik',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/BattleMechanik.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Bulkhead',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Bulkhead.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Fenris',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Fenris.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Fenris (Dismount)',
        avatar: '/Warmahordes/avatars/Fenris.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Greylord Adjunct',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/GreylordAdjunct.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Greylord Forge Seer',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/GreylordForgeSeer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Iron Fang Kovnik',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/IronFangKovnik.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Kapitan Sofya Skirova',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KapitanSofyaSkirova.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Koldun Lord',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KoldunLord.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Kontroller',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Kontroller.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'Kovnik Andrei Malakov',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KovnikAndreiMalakov.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Kovnik Apprentice Kratikoff',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KovnikApprenticeKratikoff.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Kovnik Jozef Grigorovich',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/KovnikJozefGrigorovich.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Drakhun',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarDrakhun.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Man-O-War Drakhun (Dismount)',
        avatar: '/Warmahordes/avatars/ManOWarDrakhun.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Man-O-War Kovnik',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarKovnik.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Strike Tanker',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarStrikeTanker.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Suppression Tanker',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarSuppressionTanker.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'Magziev Zariyah Volkova',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/MagzievZariyahVolkova.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Manhunter',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/Manhunter.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Mortar Team',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/MortarTeam.jpg',
    size: '50mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: "Ol' Grim",
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/OlGrim.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Orsus The Chained',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/OrsusTheChained.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Sergeant Goran Lazarenko, the Jackal',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/GoranLazarenko.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Sergeant Sergei Krol',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/SergeantSergeiKrol.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'Uhlan Kovnik Markov',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/UhlanKovnikMarkov.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'War Dog',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WarDog.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Widowmaker Marksman',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WidowmakerMarksman.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Artillery Kapitan',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardArtilleryKapitan.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Korps Officer',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterKorpsOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Yuri the Axe',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/YuritheAxe.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Yuri the Hunter',
    type: 'Solo',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/YuriTheHunter.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Assault Chariot',
    type: 'Battle Engine',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarAssaultChariot.jpg',
    size: '120mm',
    health: 28,
    maxMeleeRange: 1,
  },
  {
    name: 'Man-O-War Siege Chariot',
    type: 'Battle Engine',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/ManOWarSiegeChariot.jpg',
    size: '120mm',
    health: 28,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Guard Gun Carriage',
    type: 'Battle Engine',
    faction: 'Khador',
    avatar: '/Warmahordes/avatars/WinterGuardGunCarriage.jpg',
    size: '120mm',
    health: 38,
    maxMeleeRange: 1,
  },
  {
    name: 'Asphyxious the Hellbringer',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/AsphyxioustheHellbringer.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    includes: [
      {
        name: 'Vociferon',
        avatar: '/Warmahordes/avatars/Vociferon.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Bane Witch Agathia',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BaneWitchAgathia.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Captain Aiakos',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/CaptainAiakos.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Deneghra, the Soul Weaver',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/DeneghratheSoulWeaver.jpg',
    size: '120mm',
    health: 23,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Discerptor Eviscerus',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/DisceptorEviscerus.jpg',
    size: '40mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Goreshade the Bastard',
    type: 'Warcaster Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/GoreshadetheBastard.jpg',
    size: '40mm',
    health: 17,
    focus: 7,
    includes: [
      {
        name: 'Deathwalker',
        avatar: '/Warmahordes/avatars/Deathwalker.jpg',
        size: '30mm',
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Goreshade the Cursed',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/GoreshadetheCursed.jpg',
    size: '40mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Goreshade, Lord of Ruin',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/GoreshadeLordofRuin.jpg',
    size: '50mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Iron Lich Asphyxious',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/IronLichAsphyxious.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Lich Lord Asphyxious',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/LichLordAsphyxious.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Lich Lord Dekathus',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/LichLordDekathus.jpg',
    size: '40mm',
    health: 19,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Lich Lord Terminus',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/LichLordTerminus.jpg',
    size: '50mm',
    health: 20,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Lich Lord Venethrax',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/LichLordVenethrax.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Lord Exhumator Scaverous',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/LordExhumatorScaverous.jpg',
    size: '40mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Master Necrosurgeon Sepsira',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MasterNecrosurgeonSepsira.jpg',
    size: '30mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Master Necrotech Mortenebra',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MasterNecrotechMortenebra.jpg',
    size: '40mm',
    health: 16,
    focus: 7,
    includes: [
      {
        name: 'Deryliss',
        avatar: '/Warmahordes/avatars/Deryliss.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Mortenebra, Numen of Necrogenesis',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MortenebraNumenofNecrogenesis.jpg',
    size: '50mm',
    health: 19,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Pirate Queen Skarre',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/PirateQueenSkarre.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Skarre, Admiral of the Black Fleet',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SkarreAdmiraloftheBlackFleet.jpg',
    size: '120mm',
    health: 26,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Skarre, Queen of the Broken Coast',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SkarreQueenoftheBrokenCoast.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Sturgis the Corrupted',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SturgistheCorrupted.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'The Witch Coven of Garlghast',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Egregore.jpg',
    size: '40mm',
    focus: 9,
    alias: 'Egregore - Witch Coven',
    includes: [
      {
        name: 'Helleana1',
        avatar: '/Warmahordes/avatars/Helleana.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
      {
        name: 'Morgaen1',
        avatar: '/Warmahordes/avatars/Morgaen.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
      {
        name: 'Selene1',
        avatar: '/Warmahordes/avatars/Selene.jpg',
        size: '30mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Warwitch Deneghra',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/WarwitchDeneghra.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Wraith Witch Deneghra',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/WraithWitchDeneghra.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Wraithbinder Nekane',
    type: 'Warcaster',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/WraithbinderNekane.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Barathrum',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Barathrum.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Cankerworm',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Cankerworm.jpg',
    size: '40mm',
    damageGrid: '##HH\n##CH\n##CH\n##CM\n##CM\n##MM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Corruptor',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Corruptor.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Deathjack',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Deathjack.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Deathripper',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Deathripper.jpg',
    size: '40mm',
    damageGrid: '#FF\n#FC\n##CC\n##AM\n#AM\n#MM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Defiler',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Defiler.jpg',
    size: '40mm',
    damageGrid: '#FF\n#FC\n##CC\n##AM\n#AM\n#MM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Desecrator',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Desecrator.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Erebus',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Erebus.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Grendel',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Grendel.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n###HMM\n###HCC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Hades',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Hades.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n###HMM\n###HCC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Harrower',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Harrower.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Helldiver',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Helldiver.jpg',
    size: '40mm',
    damageGrid: '#HH\n##CH\n##CH\n##CM\n##CM\n#MM',
    focus: 0,
    includes: [
      {
        name: 'Burrow Marker (Helldiver)',
        size: '30mm',
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Inflictor',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Inflictor.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Kharybdis',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Kharybdis.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 3,
  },
  {
    name: 'Kraken',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Kraken.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n##SSCM\n##SCCM\n##SCCM\n##SSCM\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 3,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Leviathan',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Leviathan.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Malice',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Malice.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Malefactor',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Malefactor.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n###HMM\n###HCC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Nightmare',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Nightmare.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Nightwretch',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Nightwretch.jpg',
    size: '40mm',
    damageGrid: '#FF\n#FC\n##CC\n##AM\n#AM\n#MM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Raptor',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Raptor.jpg',
    size: '40mm',
    damageGrid: '#CC\n#HC\n##HC\n##HM\n#HM\n#MM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Reaper',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Reaper.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Ripjaw',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Ripjaw.jpg',
    size: '40mm',
    damageGrid: '#FF\n#FC\n##CC\n##AM\n#AM\n#MM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Scavenger',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Scavenger.jpg',
    size: '40mm',
    damageGrid: '#H\n##CH\n##CH\n##CM\n##CM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Seether',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Seether.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Sepulcher',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Sepulcher.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n##SSCM\n##SCCM\n##SCCM\n##SSCM\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 3,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Shrike',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Shrike.jpg',
    size: '40mm',
    damageGrid: '#H\n##CH\n##CH\n##CM\n##CM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Slayer',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Slayer.jpg',
    size: '50mm',
    damageGrid: '##L\n##LLM\n####MM\n####CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Stalker',
    type: 'Warjack',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Stalker.jpg',
    size: '40mm',
    damageGrid: '#L\n#LLM\n#MM\n#CC\n#RRC\n#R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Bane Knights',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BaneKnights.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Bane Riders',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BaneRiders.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Bane Warrior Officer & Standard',
    type: 'Command Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BaneWarriorOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Bane Warrior Officer',
    includes: [
      {
        name: 'Bane Warrior Standard',
        avatar: '/Warmahordes/avatars/BaneWarriorOfficerStandard.jpg',
        size: '30mm',
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Bane Warriors',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BaneWarriors.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Bile Thralls',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BileThralls.jpg',
    size: '30mm',
    count: 5,
  },
  {
    name: 'Scharde Ogrun Boarding Party',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BlackOgrunBoardingParty.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Scharde Ogrun Ironmongers',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BlackOgrunIronmongers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Scharde Ogrun Smog Belchers',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BlackOgrunSmogBelchers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: "Blackbane's Ghost Raiders",
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Blackbane.jpg',
    size: '30mm',
    health: 5,
    alias: 'Blackbane',
    includes: [
      {
        name: 'Ghost Raider Grunt',
        avatar: '/Warmahordes/avatars/GhostRaider.jpg',
        size: '30mm',
        count: 4,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Trollkin Marauders',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BlightedTrollkinMarauders.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Bloodgorgers',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Bloodgorgers.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Brute Thrall',
    type: 'Weapon Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BruteThrall.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Carrion Thralls',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/CarrionThralls.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Jussika Bloodtongue',
    type: 'Command Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/JussikaBloodtongue.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Mechanithrall Brutes',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MechanithrallBrutes.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Mechanithrall Swarm',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MechanithrallSwarm.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Mechanithralls',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Mechanithralls.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Necrosurgeon & Stitch Thralls',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/NecrosurgeonStitchThralls.jpg',
    size: '30mm',
    alias: 'Necrosurgeon',
    includes: [
      {
        name: 'Stitch Thralls',
        avatar: '/Warmahordes/avatars/StitchThrall.jpg',
        size: '30mm',
        count: 3,
      },
    ],
    maxMeleeRange: 1,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Necrosurgeon Initiates',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/NecrosurgeonInitiates.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Night Terrors',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/NightTerrors.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Revenant Cannon Crew',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/RevenantCannonCrew.jpg',
    size: '50mm',
    alias: 'Revenant Cannon Gunner',
    includes: [
      {
        name: 'Revenant Cannon Crew Grunt',
        avatar: '/Warmahordes/avatars/RevenantCannonCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Revenant Crew Rifleman',
    type: 'Weapon Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/RevenantCrewRifleman.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Revenant Crew of the Atramentous',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/RevenantCrewoftheAtramentous.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Satyxis Blood Hag',
    type: 'Command Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SatyxisBloodHag.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Satyxis Blood Witches',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SatyxisBloodWitches.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Satyxis Gunslingers',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SatyxisGunslingers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Satyxis Raider Sea Witch',
    type: 'Command Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SatyxisRaiderSeaWitch.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Satyxis Raiders',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SatyxisRaiders.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Scharde Dirge Seers',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SchardeDirgeSeers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Scharde Pirate Officer',
    type: 'Command Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SchardePirateOfficer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Scharde Pirates',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SchardePirates.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Skarlock Commander',
    type: 'Command Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SkarlockCommander.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Sludge Thralls',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SludgeThralls.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Soulhunters',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Soulhunters.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'The Furies',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Valak.jpg',
    size: '40mm',
    health: 8,
    alias: 'Valak',
    includes: [
      {
        name: 'Anathan',
        avatar: '/Warmahordes/avatars/Anathan.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
      },
      {
        name: 'Dogreth',
        avatar: '/Warmahordes/avatars/Dogreth.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'The Withershadow Combine',
    type: 'Unit',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Maelovus.jpg',
    size: '30mm',
    health: 5,
    alias: 'Maelovus1',
    includes: [
      {
        name: 'Admonia1',
        avatar: '/Warmahordes/avatars/Admonia.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
      {
        name: 'Tremulus1',
        avatar: '/Warmahordes/avatars/Tremulus.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Agrimony, Crone of the Dying Strands',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/AgrimonyCroneoftheDyingStrands.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Aiakos, Scourge of the Meredius',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/AiakosScourgeoftheMeredius.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Axiara Wraithblade',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/AxiaraWraithblade.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Bane Lord Tartarus',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BaneLordTartarus.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Bloat Thrall',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BloatThrall.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Bloat Thrall Overseer Mobius',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BloatThrallOverseerMobius.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Rengrave',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/CaptainRengrave.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Corpse Crawler',
    type: 'Solo',
    faction: 'Cryx',
    size: '30mm',
    avatar: '/Warmahordes/avatars/CorpseCrawler.jpg',
  },
  {
    name: 'Darragh Wrathe',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/DarraghWrathe.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Darragh Wrathe (Dismount)',
        avatar: '/Warmahordes/avatars/DarraghWrathe.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'General Gerlak Slaughterborn',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/GeneralGerlakSlaughterborn.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Hellslinger Phantom',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/HellslingerPhantom.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Iron Lich Commander',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/IronLichCommander.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Iron Lich Overseer',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/IronLichOverseer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Machine Wraith',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MachineWraith.jpg',
    size: '40mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Machine Wraith Dominator',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MachineWraithDominator.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Master Gurglepox',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MasterGurglepox.jpg',
    size: '40mm',
    health: 15,
    maxMeleeRange: 1,
  },
  {
    name: 'Master Necrotech Chatterbane',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MasterNecrotechChatterbane.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Misery Cage',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/MiseryCage.jpg',
    size: '40mm',
    health: 5,
    resource3Label: 'Corpses',
    resource3: 1,
  },
  {
    name: 'Necroharvester',
    type: 'Solo',
    faction: 'Cryx',
    size: '80mm',
    health: 18,
    avatar: '/Warmahordes/avatars/Necroharvester.jpg',
    maxMeleeRange: 3,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Necrotech',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/Necrotech.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
    resource3Label: 'Scrap',
    resource3: 0,
  },
  {
    name: "Ol' Grim",
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/OlGrim.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Pistol Wraith',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/PistolWraith.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Satyxis Blood Priestess',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SatyxisBloodPriestess.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Satyxis Raider Captain',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SatyxisRaiderCaptain.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Skarlock Lieutenant',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/ScarlockLieutenant.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Scrap Thrall',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/ScrapThrall.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Severa Blacktide',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SeveraBlacktide.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Skarlock Thrall',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SkarlockThrall.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Soul Trapper',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/SoulTrapper.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Warwitch Initiate Deneghra',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/WarwitchInitiateDeneghra.jpg',
    size: '30mm',
    health: 5,
    focus: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Warwitch Siren',
    type: 'Solo',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/WarwitchSiren.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Bane Knight Officer',
    type: 'Command Attachment',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/BaneKnight.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Wraith Engine',
    type: 'Battle Engine',
    faction: 'Cryx',
    avatar: '/Warmahordes/avatars/WraithEngine.jpg',
    size: '120mm',
    health: 26,
    maxMeleeRange: 2,
  },
  {
    name: 'Adeptis Rahn',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/AdeptisRahn.jpg',
    size: '30mm',
    health: 17,
    focus: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Dawnlord Vyros',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnlordVyros.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
    resource3Label: 'Synergy',
    resource3: 0,
  },
  {
    name: "Elara, Death's Shadow",
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/ElaraDeathsShadow.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Falcir the Merciless',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/FalcirTheMerciless.jpg',
    size: '30mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Garryth, Eye of Vengeance',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/GarrythEyeofVengeance.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Garryth, the Blade of Retribution',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/GarryththeBladeofRetribution.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Issyria, Sibyl of Dawn',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/IssyriaSibylofDawn.jpg',
    size: '30mm',
    health: 15,
    focus: 8,
  },
  {
    name: "Kaelyssa, the Night's Whisper",
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/KaelyssatheNightsWhisper.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 1,
    resource3Label: 'Power',
  },
  {
    name: 'Lord Arcanist Ossyan',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/LordArcanistOssyan.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Lord Ghyrrshyld, the Forgiven',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/LordGhyrrshyldtheForgiven.jpg',
    size: '40mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Magister Helynna',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/MagisterHelynna.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Ravyn, the Eternal Light',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/RavyntheEternalLight.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Thyron, Sword of Truth',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/ThyronSwordofTruth.jpg',
    size: '40mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Vyros, Incissar of the Dawnguard',
    type: 'Warcaster',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/VyrosIncissaroftheDawnguard.jpg',
    size: '50mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Aeternae',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Aeternae.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Aspis',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Aspis.jpg',
    size: '40mm',
    damageGrid: '#L\n#LLM\n##GMM\n##GCC\n#RRC\n#R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Banshee',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Banshee.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Chimera',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Chimera.jpg',
    size: '40mm',
    damageGrid: '#L\n#LLM\n#AGMM\n#AGCC\n#RRC\n#R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Daemon',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Daemon.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Discordia',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Discordia.jpg',
    size: '50mm',
    damageGrid: '#L\n#LLM\n##GMM\n##GCC\n#RRC\n#R\nFFFFF\nFFFFF',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Gorgon',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Gorgon.jpg',
    size: '40mm',
    damageGrid: '#L\n#LLM\n##GMM\n##GCC\n#RRC\n#R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Griffon',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Griffon.jpg',
    size: '40mm',
    damageGrid: '#L\n#LLM\n##GMM\n##GCC\n#RRC\n#R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Harpy',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Harpy.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Helios',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Helios.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n##GGCM\n##GCCM\n##GCCM\n##GGCM\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Hemera',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Hemera.jpg',
    size: '50mm',
    damageGrid: '#L\n##LLM\n###GMM\n###GCC\n##AAC\n#A',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Hydra',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Hydra.jpg',
    size: '50mm',
    damageGrid: '#L\n##LLM\n###GMM\n###GCC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Hyperion',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Hyperion.jpg',
    size: '120mm',
    damageGrid:
      '##LL\n##LC\n#LCM\n##LCM\n##GGCM\n##GCCM\n##GCCM\n##GGCM\n##RCM\n#RCM\n##RC\n##RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Hypnos',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Hypnos.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n###AMM\n###ACC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Imperatus',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Imperatus.jpg',
    size: '50mm',
    damageGrid: '#L\n##LLM\n###GMM\n###GCC\n##RRC\n#R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Manticore',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Manticore.jpg',
    size: '50mm',
    damageGrid: '#L\n##LLM\n###GMM\n###GCC\n##RRC\n#R\nFFFFF\nFFFFF',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Moros',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Moros.jpg',
    size: '40mm',
    damageGrid: '#L\n#LLM\n#GMM\n#GCC\n#RRC\n#R\nFFF\nFFF',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Phoenix',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Phoenix.jpg',
    size: '50mm',
    damageGrid: '#L\n##LLM\n##AGMM\n##AGCC\n##RRC\n#R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Siren',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Siren.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Sphinx',
    type: 'Warjack',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Sphinx.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Dawnguard Destors',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardDestors.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Dawnguard Invictor Officer & Standard',
    type: 'Command Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardInvictorOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Dawnguard Invictor Officer',
    includes: [
      {
        name: 'Dawnguard Invictor Standard',
        avatar: '/Warmahordes/avatars/DawnguardInvictorOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Dawnguard Invictors',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardInvictors.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Dawnguard Sentinel Officer & Standard',
    type: 'Command Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardSentinelOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Dawnguard Sentinel Officer',
    includes: [
      {
        name: 'Dawnguard Sentinel Standard',
        avatar: '/Warmahordes/avatars/DawnguardSentinelOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Dawnguard Sentinels',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardSentinels.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Eiryss, Mage Hunter Commander',
    type: 'Command Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/EiryssMageHunterCommander.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Heavy Rifle Team',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HeavyRifleTeamGunner.jpg',
    size: '50mm',
    alias: 'Heavy Rifle Team Gunner',
    includes: [
      {
        name: 'Heavy Rifle Team Grunt',
        avatar: '/Warmahordes/avatars/HeavyRifleTeamGrunt.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'House Ellowuyr Wardens',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseEllowuyrWardens.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'House Ellowuyr Swordsmen Officer & Standard',
    type: 'Command Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseEllowuyrSwordsmanOfficerStandard.jpg',
    size: '30mm',
    alias: 'House Ellowuyr Swordsmen Officer',
    includes: [
      {
        name: 'House Ellowuyr Swordsmen Standard Bearer',
        avatar:
          '/Warmahordes/avatars/HouseEllowuyrSwordsmanOfficerStandard.jpg',
        size: '30mm',
        maxMeleeRange: 2,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'House Ellowuyr Swordsmen',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseEllowuyrSwordsmen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'House Shyeel Arcanists',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseShyeelArcanists.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'House Shyeel Battle Mages',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseShyeelBattleMages.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'House Vyre Electromancers',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseVyreElectromancers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Houseguard Halberdier Officer & Standard',
    type: 'Command Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseguardHalberdierOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Houseguard Halberdier Officer',
    includes: [
      {
        name: 'Houseguard Halberdier Standard Bearer',
        avatar: '/Warmahordes/avatars/HouseguardHalberdierOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Houseguard Halberdiers',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseguardHalberdiers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Houseguard Rifleman Officer & Standard',
    type: 'Command Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseguardRiflemanOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Houseguard Rifleman Officer',
    includes: [
      {
        name: 'Houseguard Rifleman Standard Bearer',
        avatar: '/Warmahordes/avatars/HouseguardRiflemanOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Houseguard Riflemen',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseguardRiflemen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Mage Hunter Infiltrators',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/MageHunterInfiltrators.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Mage Hunter Strike Force',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/MageHunterStrikeForce.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Mage Hunter Strike Force Commander',
    type: 'Command Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/MageHunterStrikeForceCommander.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Ryssovass Defenders',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/RyssovassDefenders.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Soulless Escort',
    type: 'Weapon Attachment',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/SoullessEscort.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Spears of Scyrah',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/SpearsofScyrah.jpg',
    size: '30mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Stormfall Archers',
    type: 'Unit',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/StormfallArchers.jpg',
    size: '30mm',
    count: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Aelyth Vyr, Blade of Nyssor',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/AelythVyrBladeofNyssor.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Arcanist Mechanik',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/ArcanistMechanik.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Dawnguard Destor Thane',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardDestorThane.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Dawnguard Sentinel Scyir',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardSentinelScyir.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Elara, Tyro of the Third Chamber',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/ElaraTyrooftheThirdChamber.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Fane Knight Guardian',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/FaneKnightGuardian.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Fane Knight Skeryth Issyen',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/FaneKnightSkerythIssyen.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Fane Knight Skeryth Issyen (Dismount)',
        avatar: '/Warmahordes/avatars/FaneKnightSkerythIssyen.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Ghost Sniper',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/GhostSniper.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'House Ellowuyr Warden Executioner',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseEllowuyrWardenExecutioner.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'House Shyeel Artificer',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseShyeelArtificer.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'House Shyeel Magister',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseShyeelMagister.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Houseguard Thane',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/HouseguardThane.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Lys Healer',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/LysHealer.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Mage Hunter Assassin',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/MageHunterAssassin.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 4,
  },
  {
    name: 'Narn, Mage Hunter of Ios',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/NarnMageHunterofIos.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Nayl',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/Nayl.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Priest of Nyssor',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/PriestofNyssor.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Soulless Voidtracer',
    type: 'Solo',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/SoullessVoidtracer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Arcantrik Force Generator',
    type: 'Battle Engine',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/ArcantrikForceGenerator.jpg',
    size: '120mm',
    health: 24,
    maxMeleeRange: 1,
  },
  {
    name: 'Dawnguard Trident',
    type: 'Battle Engine',
    faction: 'Retribution of Scyrah',
    avatar: '/Warmahordes/avatars/DawnguardTrident.jpg',
    size: '120mm',
    health: 30,
    maxMeleeRange: 1,
    resource3Label: 'Power',
    resource3: 0,
  },
  {
    name: 'Archnumen Aurora',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ArchnumenAurora.jpg',
    size: '50mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: "Ashlynn D'Elyse, The Queen's Blade",
    type: 'Warcaster Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Ashlynn2.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    includes: [
      {
        name: 'Vayne di Brasico',
        avatar: '/Warmahordes/avatars/Vayne.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
      {
        name: 'Marie Aguillon',
        avatar: '/Warmahordes/avatars/Marie.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: "Ashlynn d'Elyse",
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/AshlynndElyse.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: "Caine's Hellslingers",
    type: 'Warcaster Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CainesHellslingers.jpg',
    size: '30mm',
    health: 15,
    focus: 6,
    alias: 'Caine3',
    includes: [
      {
        name: 'Ryan2',
        avatar: '/Warmahordes/avatars/Ryan2.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
        resource3Label: 'Power',
      },
      {
        name: 'Watts2',
        avatar: '/Warmahordes/avatars/Watts2.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
        resource3Label: 'Power',
      },
    ],
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Power',
  },
  {
    name: 'Captain Bartolo Montador',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CaptainBartoloMontador.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Blood Quenched',
  },
  {
    name: 'Captain Damiano',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CaptainDamiano.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
    resource3: 5,
    resource3Label: 'Coins',
  },
  {
    name: 'Captain Phinneus Shae',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CaptainPhinneusShae.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
    resource3Label: 'Power',
  },
  {
    name: 'Captain Rahera, Terror of the Wailing Sea',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CaptainRaheraTerroroftheWailingSea.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Cognifex Cyphon',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CognifexCyphon.jpg',
    size: '40mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 1,
    resource3Label: 'Power',
  },
  {
    name: 'Constance Blaize, Knight of the Prophet',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ConstanceBlaizeKnightoftheProphet.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Drake MacBain',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DrakeMacBain.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Durgen Madhammer',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DurgenMadhammer.jpg',
    size: '30mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Exulon Thexus',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ExulonThexus.jpg',
    size: '40mm',
    health: 17,
    focus: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Fiona the Black',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/FionatheBlack.jpg',
    size: '30mm',
    health: 15,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'General Ossrum',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GeneralOssrum.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Gorten Grundback',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GortenGrundback.jpg',
    size: '30mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Lieutenant Crosse, Resistance Fighter',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/LieutenantCrosseResistanceFighter.jpg',
    size: '30mm',
    health: 14,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Magnus the Traitor',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MagnustheTraitor.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Magnus the Warlord',
    type: 'Warcaster',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MagnustheWarlord.jpg',
    size: '30mm',
    health: 17,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Blockader',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Blockader.jpg',
    size: '120mm',
    damageGrid:
      '###L\n##LL\n##LM\n##LCM\n###CCM\n###CCM\n###CCM\n###CCM\n##RCM\n##RM\n##RR\n###R',
    focus: 0,
    maxMeleeRange: 4,
  },
  {
    name: 'Buccaneer',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Buccaneer.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Freebooter',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Freebooter.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Gallant',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Gallant.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Galleon',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Galleon.jpg',
    size: '120mm',
    damageGrid:
      '###L\n##LL\n##LM\n##LCM\n###CCM\n###CCM\n###CCM\n###CCM\n##RCM\n##RM\n##RR\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Ghordson Avalancher',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GhordsonAvalancher.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Ghordson Basher',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GhordsonBasher.jpg',
    size: '50mm',
    damageGrid: '##HH\n###CH\n###CH\n###CM\n###CM\n##MM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Ghordson Driller',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GhordsonDriller.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Ghordson Earthbreaker',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GhordsonEarthbreaker.jpg',
    size: '120mm',
    damageGrid:
      '###L\n##LL\n###LM\n##LCM\n###CCM\n###CCM\n###CCM\n###CCM\n##RCM\n###RM\n##RR\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Grundback Blaster',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GrundbackBlaster.jpg',
    size: '40mm',
    damageGrid: '#H\n#HH\n##HC\n##CC\n#MM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Grundback Gunner',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GrundbackGunner.jpg',
    size: '40mm',
    damageGrid: '#H\n#HH\n##HC\n##CC\n#MM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Malvin & Mayhem',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MalvinAndMayhem.jpg',
    size: '50mm',
    damageGrid: '###L-\n##LLM\n####MM\n####CC\n##RRC\n###R-',
    focus: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Mangler',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Mangler.jpg',
    size: '50mm',
    damageGrid: '###L-\n##LLM\n####MM\n####CC\n##RRC\n###R-',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Mariner',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Mariner.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Mule',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Mule.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Nomad',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Nomad.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Renegade',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Renegade.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##AMM\n##ACC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Obliterator',
  },
  {
    name: 'Rocinante',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Rocinante.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Rover',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Rover.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Scallywag',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Scallywag.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Subduer',
    type: 'Monstrosity',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Subduer.jpg',
    size: '50mm',
    damageGrid: '####LL\n####LM\n####MM\n####BB\n####RB\n####RR',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Swabber',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Swabber.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Swashbuckler',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Swashbuckler.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Talon',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Talon.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Toro',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Toro.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####CC\n##RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Vanguard',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Vanguard.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n###MM\n###CC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Warden',
    type: 'Monstrosity',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Warden.jpg',
    size: '50mm',
    damageGrid: '####LL\n####LM\n####MM\n####BB\n####RB\n####RR',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Wrecker',
    type: 'Monstrosity',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Wrecker.jpg',
    size: '50mm',
    damageGrid: '####LL\n####LM\n####MM\n####BB\n####RB\n####RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Wroughthammer Rockram',
    type: 'Warjack',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/WroughthammerRockram.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n####MM\n####CC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Alexia Ciannor & the Risen',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/AlexiaCiannortheRisen.jpg',
    size: '30mm',
    health: 5,
    alias: 'Alexia1',
    includes: [
      {
        name: 'Risen',
        avatar: '/Warmahordes/avatars/Risen.jpg',
        size: '30mm',
        count: 9,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Corpse',
  },
  {
    name: 'Asphyxious the Sanctified',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/AsphyxioustheSanctified.jpg',
    size: '50mm',
    health: 17,
    focus: 5,
    includes: [
      {
        name: 'Annihilation Servitors',
        avatar: '/Warmahordes/avatars/AnnihilationServitors.jpg',
        size: '30mm',
        count: 3,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Captain Sam MacHorne & the Devil Dogs',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SamMachorne.jpg',
    size: '30mm',
    health: 5,
    alias: 'Captain Sam MacHorne',
    includes: [
      {
        name: 'Devil Dog',
        avatar: '/Warmahordes/avatars/DevilDogs.jpg',
        size: '30mm',
        count: 4,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Cephalyx Dominator',
    type: 'Command Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Dominated Hammerfall High Shield Gun Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Hammerfall High Shield Gun Corps',
        avatar: '/Warmahordes/avatars/HammerfallHighShieldGunCorps.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Dominated Horgenhold Forge Guard',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Horgenhold Forge Guard',
        avatar: '/Warmahordes/avatars/HorgenholdForgeGuard.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 2,
      },
    ],
  },
  {
    name: 'Dominated Horgenhold Artillery Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Horgenhold Artillery Corps Gunner',
        avatar: '/Warmahordes/avatars/HorgenholdArtilleryCorps.jpg',
        size: '50mm',
        alias: 'Gunner',
      },
      {
        name: 'Horgenhold Artillery Corps Grunt',
        avatar: '/Warmahordes/avatars/HorgenholdArtilleryCorpsGrunt.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Dominated Ogrun Assault Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Ogrun Assault Corps',
        avatar: '/Warmahordes/avatars/OgrunAssaultCorps.jpg',
        size: '40mm',
        health: 5,
        count: 3,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Dominated Steelhead Cannon Crew',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Steelhead Cannon Crew Gunner',
        avatar: '/Warmahordes/avatars/SteelheadCannonCrew.jpg',
        size: '50mm',
      },
      {
        name: 'Steelhead Cannon Crew Grunt',
        avatar: '/Warmahordes/avatars/SteelheadCannonCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Dominated Steelhead Halberdiers',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Steelhead Halberdiers',
        avatar: '/Warmahordes/avatars/SteelheadHalberdiers.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 2,
      },
    ],
  },
  {
    name: 'Dominated Steelhead Mortar Crew',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Steelhead Mortar Crew Gunner',
        avatar: '/Warmahordes/avatars/SteelheadMortarCrew.jpg',
        size: '50mm',
      },
      {
        name: 'Steelhead Mortar Crew Grunt',
        avatar: '/Warmahordes/avatars/SteelheadMortarCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Dominated Steelhead Riflemen',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Steelhead Riflemen',
        avatar: '/Warmahordes/avatars/SteelheadRiflemen.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Dominated Steelhead Volley Gun Crew',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Steelhead Volley Gun Crew Gunner',
        avatar: '/Warmahordes/avatars/SteelheadVolleyGunCrew.jpg',
        size: '50mm',
      },
      {
        name: 'Steelhead Volley Gun Crew Grunt',
        avatar: '/Warmahordes/avatars/SteelheadVolleyGunCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Dominated Tactical Arcanist Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxDominator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    alias: 'Cephalyx Dominator',
    includes: [
      {
        name: 'Tactical Arcanist Corps',
        avatar: '/Warmahordes/avatars/TacticalArcanistCorps.jpg',
        size: '30mm',
        count: 3,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Cephalyx Mind Bender & Drudges',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MindBender.jpg',
    size: '30mm',
    health: 5,
    alias: 'Cephalyx Mind Bender',
    includes: [
      {
        name: 'Cephalyx Mind Bender Drudges',
        avatar: '/Warmahordes/avatars/MindBenderDrudge.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Cephalyx Mind Slaver & Drudges',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MindSlaver.jpg',
    size: '30mm',
    health: 5,
    alias: 'Cephalyx Mind Slaver',
    includes: [
      {
        name: 'Cephalyx Mind Slaver Drudges',
        avatar: '/Warmahordes/avatars/MindSlaverDrudge.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Cephalyx Overlords',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxOverlords.jpg',
    size: '30mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: "Croe's Cutthroats",
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CroesCutthroats.jpg',
    size: '30mm',
    alias: 'Croe',
    includes: [
      {
        name: "Croe's Cuthroat",
        avatar: '/Warmahordes/avatars/CroesCutthroats.jpg',
        size: '30mm',
        count: 4,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Cylena Raefyll & Nyss Hunters',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Cylena.jpg',
    size: '30mm',
    health: 5,
    alias: 'Cylena Raefyll',
    includes: [
      {
        name: 'Nyss Hunter',
        avatar: '/Warmahordes/avatars/NyssHunter.jpg',
        size: '30mm',
        count: 4,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Dannon Blythe & Bull',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DannonBlythe.jpg',
    size: '30mm',
    health: 5,
    alias: 'Blythe1',
    includes: [
      {
        name: 'Bull1',
        avatar: '/Warmahordes/avatars/Bull.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Greygore Boomhowler & Co.',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GreygoreBoomhowlerCo.jpg',
    size: '40mm',
    health: 8,
    alias: 'Boomhowler1',
    includes: [
      {
        name: 'Boomhowler Grunt',
        avatar: '/Warmahordes/avatars/GreygoreBoomhowlerCo.jpg',
        size: '40mm',
        count: 4,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Hammerfall High Shield Gun Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HammerfallHighShieldGunCorps.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Hammerfall High Shield Officer & Standard',
    type: 'Command Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HammerfallHighShieldOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Hammerfall High Shield Officer',
    includes: [
      {
        name: 'Hammerfall High Shield Standard Bearer',
        avatar: '/Warmahordes/avatars/HammerfallHighShieldOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Herne & Jonne',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Herne.jpg',
    size: '30mm',
    health: 5,
    alias: 'Herne1',
    includes: [
      {
        name: 'Jonne1',
        avatar: '/Warmahordes/avatars/Jonne.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Horgenhold Artillery Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HorgenholdArtilleryCorps.jpg',
    size: '50mm',
    alias: 'Siege Cannon',
    includes: [
      {
        name: 'Horgenhold Artillery Corps Grunt',
        avatar: '/Warmahordes/avatars/HorgenholdArtilleryCorpsGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Horgenhold Forge Guard',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HorgenholdForgeGuard.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Idrian Skirmisher Chieftain & Guide',
    type: 'Command Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/IdrianSkirmisherChieftain.jpg',
    size: '30mm',
    health: 5,
    alias: 'Idrian Skirmisher Chieftain',
    includes: [
      {
        name: 'Idrian Skirmisher Guide',
        avatar: '/Warmahordes/avatars/IdrianSkirmisherGuide.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Idrian Skirmishers',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/IdrianSkirmishers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Kayazy Assassin Underboss',
    type: 'Command Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/KayazyAssassinUnderboss.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Kayazy Assassins',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/KayazyAssassins.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Kayazy Eliminators',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/KayazyEliminators.jpg',
    size: '30mm',
    health: 5,
    count: 2,
    maxMeleeRange: 1,
  },
  {
    name: 'Lady Aiyana & Master Holt',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/LadyAiyana.jpg',
    size: '30mm',
    health: 5,
    alias: 'Aiyana1',
    includes: [
      {
        name: 'Holt1',
        avatar: '/Warmahordes/avatars/MasterHolt.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Legion of Lost Souls',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/LegionofLostSouls.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Morrowan Battle Priest',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MorrowanBattlePriest.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Mr. Walls, the Quartermaster',
    type: 'Command Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MrWallstheQuartermaster.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Ogrun Assault Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/OgrunAssaultCorps.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Order of Illumination Resolutes',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/OrderofIlluminationResolutes.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Order of Illumination Vigilants',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/OrderofIlluminationVigilants.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Precursor Knight Officer & Standard',
    type: 'Command Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/PrecursorKnightOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Precursor Knight Officer',
    includes: [
      {
        name: 'Precursor Knight Standard Bearer',
        avatar: '/Warmahordes/avatars/PrecursorKnightOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Precursor Knights',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/PrecursorKnights.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Press Gangers',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Lass.jpg',
    size: '30mm',
    alias: 'Lass',
    includes: [
      {
        name: 'Press Ganger',
        avatar: '/Warmahordes/avatars/PressGangerGrunt.jpg',
        size: '30mm',
        count: 4,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Sea Dog Deck Gun',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SeaDogDeckGun.jpg',
    size: '50mm',
    alias: 'Sea Dog Deck Gun Gunner',
    includes: [
      {
        name: 'Sea Dog Deck Gun Grunt',
        avatar: '/Warmahordes/avatars/SeaDogDeckGun.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Sea Dog Rifleman',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SeaDogPirateRifleman.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Sea Dog Pirates',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SeaDogPirates.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Steelhead Cannon Crew',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadCannonCrew.jpg',
    size: '50mm',
    alias: 'Steelhead Cannon',
    includes: [
      {
        name: 'Steelhead Cannon Crew Grunt',
        avatar: '/Warmahordes/avatars/SteelheadCannonCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Steelhead Halberdiers',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadHalberdiers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Steelhead Heavy Cavalry',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadHeavyCavalry.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Steelhead Mortar Crew',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadMortarCrew.jpg',
    size: '50mm',
    alias: 'Steelhead Mortar',
    includes: [
      {
        name: 'Steelhead Mortar Crew Grunt',
        avatar: '/Warmahordes/avatars/SteelheadMortarCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Steelhead Riflemen',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadRiflemen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Steelhead Volley Gun Crew',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadVolleyGunCrew.jpg',
    size: '50mm',
    alias: 'Steelhead Volley Gun',
    includes: [
      {
        name: 'Steelhead Volley Gun Crew Grunt',
        avatar: '/Warmahordes/avatars/SteelheadVolleyGunCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Stone Lord Guvul Godor',
    type: 'Command Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/StoneLordGuvulGodor.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Tactical Arcanist Corps',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/TacticalArcanistCorps.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'The Commodore Cannon & Crew',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/TheCommodoreCannonCrew.jpg',
    size: '50mm',
    health: 10,
    alias: 'Commodore1',
    includes: [
      {
        name: 'The Commodore Cannon Crew Grunt',
        avatar: '/Warmahordes/avatars/CommodoreCrew.jpg',
        size: '30mm',
        count: 3,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: "The Devil's Shadow Mutineers",
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Mar.jpg',
    size: '30mm',
    health: 5,
    alias: 'Mar1',
    includes: [
      {
        name: 'Morland1',
        avatar: '/Warmahordes/avatars/Morland.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
      {
        name: 'Zira1',
        avatar: '/Warmahordes/avatars/Zira.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Thorn Gun Mages',
    type: 'Unit',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ThornGunMages.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Alexia, the Undying',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/AlexiatheUndying.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Alexia, Mistress of the Witchfire',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/AlexiaMistressoftheWitchfire.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'Alexia, Queen of the Damned',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/AlexiaQueenOfTheDamned.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Anastasia di Bray',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/AnastasiadiBray.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Balthazar Bamfist',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/BalthazarBamfist.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Bellighul, Master of Pain',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Bellighul.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Black Bella, Duchess of Dread',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/BlackBellaDuchessofDread.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Bloody Bradigan',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/BloodyBradigan.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Boomhowler Solo Artist',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/BoomhowlerSoloArtist.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
    alias: 'Boomhowler, Solo Artist',
  },
  {
    name: 'Boomhowler the Destroyer',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/BoomhowlerTheDestroyer.jpg',
    size: '40mm',
    health: 12,
    maxMeleeRange: 2,
  },
  {
    name: 'Boss Machorne',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/BossMachorn.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 3,
  },
  {
    name: 'Bosun Grogspar',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/BosunGrogspar.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Crawtooth',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CaptainCrawtooth.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Carver Ultimus',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CarverUltimus.jpg',
    size: '40mm',
    health: 12,
    focus: 5,
    includes: [
      {
        name: 'War Boar MMD47',
        type: 'Warbeast',
        avatar: '/Warmahordes/avatars/WarBoarMMD47.jpg',
        size: '80mm',
        damageGrid: 'MMMM\nMM\nMMM\nBBBBBBBB\nBB\nBBBBBBB\nSSSS\nSS\nSSS',
        fury: 0,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Cephalyx Agitator',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/CephalyxAgitator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Colbie Sterling, Captain of the BRI',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ColbieSterlingCaptainoftheBRI.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Death Archon',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DeathArchon.jpg',
    size: '50mm',
    health: 15,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 1,
  },
  {
    name: 'Dez',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Dez.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Loot',
  },
  {
    name: 'Dez & Gubbin, Dynamite Duo',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DezAndGubbin.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
    alias: 'Dez & Gubbin',
  },
  {
    name: 'Dirty Meg',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DirtyMeg.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Doc Killingsworth',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DocKillingsworth.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Doctor Stygius',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DrStygius.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Dreyfus & Flubbin, Storm Patrol',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/DreyfusAndFlubbin.jpg',
    size: '50mm',
    health: 8,
    maxMeleeRange: 2,
    alias: 'Dreyfus & Flubbin',
  },
  {
    name: 'Eilish Garrity, the Dark Traitor',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/EilishGarrityDarkTraitor.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Eilish Garrity, the Occultist',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/EilishGarritytheOccultist.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Eiryss, Fortune Hunter of Ios',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/EiryssFortuneHunterofIos.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Eiryss, Angel of Retribution',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/EiryssAngelofRetribution.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Eiryss, Mage Hunter of Ios',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/EiryssMageHunterofIos.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Eiryss, Shadow of Retribution',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/EiryssShadowofRetribution.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'First Mate Hawk',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/FirstMateHawk.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Flubbin',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Flubbin.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Flugwug The Filcher',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/FlugwugTheFilcher.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 4,
    resource3: 0,
    resource3Label: 'Loot',
  },
  {
    name: 'Gastone Crosse',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GastoneCrosse.jpg',
    size: '30mm',
    health: 5,
    focus: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Glyn Cormier, Illuminated One',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GlynCormierIlluminatedOne.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Gorman di Wulfe, Rogue Alchemist',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GormandiWulfeRogueAlchemist.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Gorman the Mad',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GormantheMad.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Greygore Boomhowler',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GreygoreBoomhowler.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Princess Delores Graciela',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Graciela.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Grand Master Gabriel Throne',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/GrandMasterGabrielThrone.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Gubbin',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Gubbin.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Harlan Versh, Illuminated One',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HarlanVershIlluminatedOne.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Harlowe Holdemhigh',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HarloweHoldemhigh.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Hermit of Henge Hold',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HermitofHengeHold.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'J.A.I.M.s',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/JAIMs.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Kell Bailoch',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/KellBailoch.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Koldun Lord Damien Korovnik',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/KoldunLordDamienKorovnik.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Krueger, Wrath of Blighterghast',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/KruegerWrathofBlighterghast.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 0,
  },
  {
    name: 'Lord Rockbottom',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/LordRockbottom.jpg',
    size: '30mm',
    health: 5,
    resource3: 5,
    resource3Label: 'Coins',
    maxMeleeRange: 1,
  },
  {
    name: 'Madame Moriarty',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MadameMoriarty.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Madelyn Corbeau, Ordic Courtesan',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MadelynCorbeauOrdicCourtesan.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Major Harrison Gibbs',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MajorHarrisonGibbs.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Malvin, Revenge Artist',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Malvin2.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Master Gunner Dougal MacNaile',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MasterGunnerDougalMacNaile.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Maulgreth, the Charnel Plague',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MaulgreththeCharnelPlague.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Menite Archon',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MeniteArchon.jpg',
    size: '50mm',
    health: 15,
    maxMeleeRange: 2,
  },
  {
    name: 'Morrowan Archon',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MorrowanArchon.jpg',
    size: '50mm',
    health: 12,
    maxMeleeRange: 2,
  },
  {
    name: 'Nissak, Totem Huntress Champion',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Nissak.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Ogrun Bokur',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/OgrunBokur.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Orin Midwinter, Rogue Inquisitor',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/OrinMidwinterRogueInquisitor.jpg',
    size: '30mm',
    health: 5,
    resource3Label: 'Power Tokens',
    resource3: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Prisoner 102822',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Prisoner102822.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Powder Monkey',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/PowderMonkey.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Ragman',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Ragman.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Raluk Moorclaw, the Ironmonger',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/RalukMoorclawtheIronmonger.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Reinholdt, Gobber Speculator',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ReinholdtGobberSpeculator.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Rhupert Carvolo, Piper of Ord',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/RhupertCarvoloPiperofOrd.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Rutger Shaw, Professional Adventurer',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/RutgerShawProfessionalAdventurer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Savio Montero Acosta',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SavioMonteroAcosta.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Scythe',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Scythe.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Sergeant Nicolas Verendrye',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SergeantNicolasVerendrye.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Stannis Brocker',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/StannisBrocker.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Steelhead Arcanist',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadArcanist.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Steelhead Gunner',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadGunner.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Steelhead Ironhead',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SteelheadIronhead.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'Stonecold Black Bella',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/StonecoldBlackBella.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 4,
  },
  {
    name: 'Swamp Gobber River Raider',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SwampGobberRiverRaider.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Sylys Wyshnalyrr, the Seeker',
    type: 'Warcaster Attachment',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/SylysWyshnalyrrtheSeeker.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Taryn di la Rovissi, Llaelese Gun Mage',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/TaryndilaRovissiLlaeleseGunMage.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Thamarite Advocate',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ThamariteAdvocate.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Thamarite Archon',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ThamariteArchon.jpg',
    size: '50mm',
    health: 10,
  },
  {
    name: 'Thor Steinhammer',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ThorSteinhammer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Thrall Warrior',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ThrallWarrior.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Tubbin',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Tubbin.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Underboss Vizkoya',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/UnderbossVizkoya.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Void Archon',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/VoidArchon.jpg',
    size: '50mm',
    health: 12,
    maxMeleeRange: 1,
  },
  {
    name: 'Weird Wendell, Gremlin Wrangler',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/WeirdWendellGremlinWrangler.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Widget, Mathlete Archeologist',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Widget2.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Widget, Tinker Extraordinaire',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/WidgetTinkerExtraordinaire.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Zacchaeus, The Winter’s Chill',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/Zacchaeus.jpg',
    size: '50mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Artificer Prime Nemo and Arcane Mechaniks',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/ArtificerPrimeNemoandArcaneMechaniks.jpg',
    size: '120mm',
    health: 16,
    alias: 'Artificer Prime Nemo',
    focus: 5,
    includes: [
      {
        name: 'Arcane Mechanik',
        type: 'Unit',
        avatar: '/Warmahordes/avatars/ArcaneMechaniks.jpg',
        size: '30mm',
        count: 3,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Hammerfall Siege Crawler',
    type: 'Battle Engine',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/HammerfallSiegeCrawler.jpg',
    size: '120mm',
    health: 40,
    maxMeleeRange: 1,
  },
  {
    name: 'Aurora, the Numen of Aerogenesis',
    type: 'Warcaster',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/AuroratheNumenofAerogenesis.jpg',
    size: '30mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Axis, the Harmonic Enforcer',
    type: 'Warcaster',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/AxistheHarmonicEnforcer.jpg',
    size: '40mm',
    health: 18,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Eminent Configurator Orion',
    type: 'Warcaster',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/EminentConfiguratorOrion.jpg',
    size: '40mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Father Lucant, the Divinity Architect',
    type: 'Warcaster',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/FatherLucanttheDivinityArchitect.jpg',
    size: '50mm',
    health: 21,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Forge Master Syntherion',
    type: 'Warcaster',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/ForgeMasterSyntherion.jpg',
    size: '40mm',
    health: 20,
    focus: 6,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Synergy',
  },
  {
    name: 'Iron Mother Directrix & Exponent Servitors',
    type: 'Warcaster Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/IronMotherDirectrixExponentServitors.jpg',
    size: '40mm',
    health: 18,
    focus: 8,
    alias: 'Iron Mother Directrix',
    includes: [
      {
        name: 'Exponent Servitor',
        avatar: '/Warmahordes/avatars/ExponentServitor.jpg',
        size: '30mm',
        count: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Assimilator',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Assimilator.jpg',
    size: '50mm',
    damageGrid: '##M\n###LM\n###LLI\n###RRI\n###RM\n##M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Cipher',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Cipher.jpg',
    size: '50mm',
    damageGrid: '###M\n####LM\n###LLI\n###RRI\n####RM\n###M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Conservator',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Conservator.jpg',
    size: '50mm',
    damageGrid: '##M\n###LM\n###LLI\n###RRI\n###RM\n##M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Corollary',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Corollary.jpg',
    size: '40mm',
    damageGrid: 'M\n##M\n###II\n###II\n##M\nM',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Diffuser',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Diffuser.jpg',
    size: '40mm',
    damageGrid: '#M\n#FM\n####FI\n####FI\n#FM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Galvanizer',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Galvanizer.jpg',
    size: '40mm',
    damageGrid: '#M\n#FM\n####FI\n####FI\n#FM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Inverter',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Inverter.jpg',
    size: '50mm',
    damageGrid: '###M\n####LM\n###LLI\n###RRI\n####RM\n###M',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Mitigator',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Mitigator.jpg',
    size: '40mm',
    damageGrid: '#M\n#FM\n####FI\n####FI\n#FM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Modulator',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Modulator.jpg',
    size: '50mm',
    damageGrid: '##M\n###LM\n###LLI\n###RRI\n###RM\n##M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Monitor',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Monitor.jpg',
    size: '50mm',
    damageGrid: '###M\n####LM\n###LLI\n###RRI\n####RM\n###M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Negator',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Negator.jpg',
    size: '40mm',
    damageGrid: '#M\n#LM\n####LI\n####RI\n#RM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Prime Axiom',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/PrimeAxiom.jpg',
    size: '120mm',
    damageGrid:
      '----LM\n#LM\n###LIM\n###LIM\n##SSIM\n##SIII\n##SIII\n##SSIM\n###RIM\n###RIM\n#RM\n----RM',
    focus: 0,
    maxMeleeRange: 2,
    includes: [
      {
        name: 'Accretion Servitors',
        type: 'Solo',
        faction: 'Convergence of Cyriss',
        avatar: '/Warmahordes/avatars/AccretionServitors.jpg',
        size: '30mm',
      },
      {
        name: 'Attunement Servitors',
        type: 'Solo',
        faction: 'Convergence of Cyriss',
        avatar: '/Warmahordes/avatars/AttunementServitors.jpg',
        size: '30mm',
      },
      {
        name: 'Elimination Servitors',
        type: 'Solo',
        faction: 'Convergence of Cyriss',
        avatar: '/Warmahordes/avatars/EliminationServitors.jpg',
        size: '30mm',
      },
      {
        name: 'Reflex Servitors',
        type: 'Solo',
        faction: 'Convergence of Cyriss',
        avatar: '/Warmahordes/avatars/ReflexServitors.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Prime Conflux',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/PrimeConflux.jpg',
    size: '120mm',
    damageGrid:
      '----LM\n#LM\n###LIM\n###LIM\n##SSIM\n##SIII\n##SIII\n##SSIM\n###RIM\n###RIM\n#RM\n----RM',
    focus: 0,
    includes: [
      {
        name: 'Ionization Servitor',
        avatar: '/Warmahordes/avatars/IonizationServitor.jpg',
        size: '30mm',
        count: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Tesselator',
    type: 'Warjack',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Tessselator.jpg',
    size: '40mm',
    damageGrid: '#M\n#LM\n####LI\n####RI\n#RM\n#M',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Clockwork Angels',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/ClockworkAngels.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Eradicators',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Eradicators.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Negation Angels',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/NegationAngels.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Obstructors',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Obstructors.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Optifex Directive',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/OptifexDirective.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Perforators',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Perforators.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Reciprocators',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Reciprocators.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Reductors',
    type: 'Unit',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Reductors.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Transverse Enumerator',
    type: 'Command Attachment',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/TransverseEnumerator.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Accretion Servitors',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/AccretionServitors.jpg',
    size: '30mm',
    count: 3,
  },
  {
    name: 'Algorithmic Dispersion Optifex',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/AlgorithmicDispersionOptifex.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Attunement Servitors',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/AttunementServitors.jpg',
    size: '30mm',
    count: 3,
  },
  {
    name: 'Destructotron 3000',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/Destructotron.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Elimination Servitors',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/EliminationServitors.jpg',
    size: '30mm',
    count: 3,
  },
  {
    name: 'Enigma Foundry',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/EnigmaFoundry.jpg',
    size: '50mm',
    health: 10,
  },
  {
    name: 'Frustum Locus',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/FrustumLocus.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Power',
  },
  {
    name: 'Prefect Hypatia',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/PrefectHypatia.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Reflex Servitors',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/ReflexServitors.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Steelsoul Protector',
    type: 'Solo',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/SteelsoulProtector.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Transfinite Emergence Projector',
    type: 'Battle Engine',
    faction: 'Convergence of Cyriss',
    avatar: '/Warmahordes/avatars/TransfiniteEmergenceProjector.jpg',
    size: '120mm',
    health: 23,
    includes: [
      {
        name: 'Permutation Servitor',
        avatar: '/Warmahordes/avatars/PermutationServitor.jpg',
        size: '30mm',
        count: 1,
      },
    ],
  },
  {
    name: 'Borka Kegslayer',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/BorkaKegslayer.jpg',
    size: '40mm',
    health: 18,
    fury: 5,
    includes: [
      {
        name: 'Keg Carrier',
        avatar: '/Warmahordes/avatars/KegCarrier.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Borka, Vengeance of the Rimeshaws',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/BorkaVengeanceoftheRimeshaws.jpg',
    size: '50mm',
    health: 18,
    fury: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Brigadier General Gunnbjorn',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/BrigadierGeneralGunnbjorn.jpg',
    size: '40mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Calandra Truthsayer, Oracle of the Glimmerwood',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/CalandraTruthsayerOracleoftheGlimmerwood.jpg',
    size: '40mm',
    health: 16,
    fury: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Gunnbjorn',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/CaptainGunnbjorn.jpg',
    size: '40mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Grim Angus',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/GrimAngus.jpg',
    size: '40mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Grissel Bloodsong, Fell Caller',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/GrisselBloodsongFellCaller.jpg',
    size: '40mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Grissel Bloodsong, Marshal of the Kriels',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/GrisselBloodsongMarshaloftheKriels.jpg',
    size: '40mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Hoarluk Doomshaper, Dire Prophet & Scroll Bearers',
    type: 'Warlock Unit',
    faction: 'Trollbloods',
    avatar:
      '/Warmahordes/avatars/HoarlukDoomshaperDireProphetScrollBearers.jpg',
    size: '40mm',
    health: 16,
    fury: 7,
    alias: 'Hoarluk Doomshaper, Dire Prophet',
    includes: [
      {
        name: 'Scroll Bearers',
        avatar: '/Warmahordes/avatars/ScrollBearers.jpg',
        size: '40mm',
        health: 5,
        resource3: 0,
        resource3Label: 'Scroll',
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Hoarluk Doomshaper, Rage of Dhunia',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/HoarlukDoomshaperRageofDhunia.jpg',
    size: '40mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Hoarluk Doomshaper, Shaman of the Gnarls',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/HoarlukDoomshaperShamanoftheGnarls.jpg',
    size: '40mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Horgle, the Anvil',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/HorgletheAnvil.jpg',
    size: '40mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Hunters Grim',
    type: 'Warlock Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/HuntersGrim.jpg',
    size: '40mm',
    health: 17,
    alias: 'Grim2',
    fury: 6,
    includes: [
      {
        name: 'Muggs1',
        avatar: '/Warmahordes/avatars/Muggs.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
      {
        name: 'Krump1',
        avatar: '/Warmahordes/avatars/Krump.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Jarl Skuld, Devil of the Thornwood',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/JarlSkuldDeviloftheThornwood.jpg',
    size: '40mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 1,
    resource3Label: 'Power',
  },
  {
    name: 'Kolgrima Stonetruth, Winter Witch',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/KolgrimaStonetruthWinterWitch.jpg',
    size: '40mm',
    health: 15,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Madrak Ironhide, Thornwood Chieftain',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/MadrakIronhideThornwoodChieftain.jpg',
    size: '40mm',
    health: 18,
    fury: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Madrak Ironhide, World Ender',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/MadrakIronhideWorldEnder.jpg',
    size: '40mm',
    health: 18,
    fury: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Madrak, Great Chieftain',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/MadrakGreatChieftain.jpg',
    size: '40mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Ragnor Skysplitter, the Runemaster',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/RagnorSkysplittertheRunemaster.jpg',
    size: '40mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Warchief Bagadibawm',
    type: 'Warlock',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/WarchiefBagadibawm.jpg',
    size: '40mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Abyssal King',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/AbyssalKing.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMMMM\nMM\nMMMMMMM\nBBBBBBBBBB\nBB\nBBBBBBBBBB\nSSSSSSSS\nSS\nSSSSSSS',
    fury: 0,
    maxMeleeRange: 3,
  },
  {
    name: 'Dire Troll Blitzer',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/DireTrollBlitzer.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS\n',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dire Troll Bomber',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/DireTrollBomber.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dire Troll Brawler',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/DireTrollBrawler.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Dire Troll Mauler',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/DireTrollMauler.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dozer & Smigg',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/DozerSmigg.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Earthborn Dire Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/EarthbornDireTroll.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBB\nBB\nBBBB\nSSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Glacier King',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/GlacierKing.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMM\nMM\nMMMMM\nBBBBBBBBBBB\nBB\nBBBBBBBBB\nSSSSSSSSSS\nSS\nSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Ice Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/IceTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Mountain King',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/MountainKing.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMM\nMM\nMMMMM\nBBBBBBBBBB\nBB\nBBBBBBBBBB\nSSSSSSSSS\nSS\nSSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Mulg the Ancient',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/MulgtheAncient.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBBB\nBB\nBBBBB\nSSSSS\nSS\nSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Night Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/NightTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyre Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/PyreTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Rök',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/Rk.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Sea King',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/SeaKing.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMM\nMM\nMMMMM\nBBBBBBBBBB\nBB\nBBBBBBBBBB\nSSSSSSSSS\nSS\nSSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Slag Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/SlagTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Storm Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/StormTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Swamp Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/SwampTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Troll Axer',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollAxer.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Troll Basher',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollBasher.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Troll Bouncer',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollBouncer.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Troll Impaler',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollImpaler.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Vorogger',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/Vorogger.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Troll',
    type: 'Warbeast',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/WinterTroll.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dhunian Knot',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/DhunianKnot.jpg',
    size: '40mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Fire Spitters',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/FireSpitters.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Kriel Warrior Caber Thrower',
    type: 'Weapon Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/KrielWarriorCaberThrower.jpg',
    size: '40mm',
    maxMeleeRange: 2,
  },
  {
    name: 'Kriel Warrior Standard & Piper',
    type: 'Command Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/KrielWarriorStandardPiper.jpg',
    size: '40mm',
    alias: 'Kriel Warrior Standard Bearer',
    standardBearer: true,
    includes: [
      {
        name: 'Kriel Warrior Piper',
        avatar: '/Warmahordes/avatars/KrielWarriorStandardPiper.jpg',
        size: '40mm',
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Kriel Warriors',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/KrielWarriors.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Krielstone Bearer & Stone Scribes',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/KrielstoneBearerStoneScribes.jpg',
    size: '40mm',
    alias: 'Krielstone Bearer',
    includes: [
      {
        name: 'Stone Scribe',
        avatar: '/Warmahordes/avatars/StoneScribe.jpg',
        count: 3,
        maxMeleeRange: 1,
        size: '40mm',
      },
    ],
  },
  {
    name: 'Bear Handler & Battle Bears',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/BearHandler.jpg',
    size: '40mm',
    health: 5,
    alias: 'Bear Handler',
    includes: [
      {
        name: 'Battle Bear',
        avatar: '/Warmahordes/avatars/BattleBear.jpg',
        size: '50mm',
        health: 8,
        maxMeleeRange: 1,
        count: 2,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Northkin Elder',
    type: 'Command Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/NorthkinElder.jpg',
    size: '40mm',
    health: 5,
  },
  {
    name: 'Northkin Fire Eaters',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/NorthkinFireEaters.jpg',
    size: '40mm',
    health: 5,
    count: 2,
    includes: [
      {
        name: 'Pyg Grunt',
        avatar: '/Warmahordes/avatars/NorthkinFireEaters.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Northkin Raiders',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/NorthkinRaiders.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Pummeler Crew',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/PummelerCrew.jpg',
    size: '50mm',
    alias: 'Pummeler Crew Gunner',
    includes: [
      {
        name: 'Pummeler Crew Grunt',
        avatar: '/Warmahordes/avatars/PummelerCrewGrunt.jpg',
        size: '40mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Burrowers',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/PygBurrowers.jpg',
    size: '30mm',
    count: 5,
    includes: [
      {
        name: 'Burrow Marker (Pyg Burrowers)',
        size: '30mm',
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Bushwhacker Officer & Mortar',
    type: 'Command Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/PygBushwhackerOfficerMortar.jpg',
    size: '30mm',
    health: 5,
    alias: 'Pyg Bushwhacker Officer',
    includes: [
      {
        name: 'Mortar Pyg',
        avatar: '/Warmahordes/avatars/PygBushwhackerOfficerMortar.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Bushwhackers',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/PygBushwhackers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Lookouts',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/PygLookouts.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Scattergunner Officer & Standard',
    type: 'Command Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/ScattergunnerOfficerStandard.jpg',
    size: '40mm',
    health: 5,
    alias: 'Scattergunner Officer',
    includes: [
      {
        name: 'Scattergunner Standard Bearer',
        avatar: '/Warmahordes/avatars/ScattergunnerOfficerStandard.jpg',
        size: '40mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Scattergunners',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/Scattergunners.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Skaldi Bonehammer',
    type: 'Command Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/SkaldiBonehammer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Sons of Bragg',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/Wrathar.jpg',
    size: '40mm',
    health: 8,
    alias: 'Wrathar1',
    includes: [
      {
        name: 'Tor1',
        avatar: '/Warmahordes/avatars/Tor.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
      },
      {
        name: 'Rhudd1',
        avatar: '/Warmahordes/avatars/Rhudd.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Spirit Shamans',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/SpiritShamans.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Stone Scribe Elder',
    type: 'Command Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/StoneScribeElder.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Thumper Crew',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/ThumperCrew.jpg',
    size: '50mm',
    alias: 'Thumper',
    includes: [
      {
        name: 'Thumper Crew Grunt',
        avatar: '/Warmahordes/avatars/ThumperCrewGrunt.jpg',
        size: '40mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Barrage Team',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinBarrageTeam.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Champions',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinChampions.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Fennblade Officer & Drummer',
    type: 'Command Attachment',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinFennbladeOfficer.jpg',
    size: '40mm',
    health: 8,
    alias: 'Trollkin Fennblade Officer',
    includes: [
      {
        name: 'Trollkin Fennblade Drummer',
        avatar: '/Warmahordes/avatars/TrollkinFennbladeDrummer.jpg',
        size: '40mm',
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Trollkin Fennblades',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinFennblades.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Trollkin Highwaymen',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinHighwaymen.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Long Riders',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinLongRiders.jpg',
    size: '50mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Trollkin Runeshapers',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinRuneshapers.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Scouts',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinScouts.jpg',
    size: '40mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Sluggers',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinSluggers.jpg',
    size: '40mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Sorcerer',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinSorcerer.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Warders',
    type: 'Unit',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinWarders.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Braylen Wanderheart, Trollkin Outlaw',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/BraylenWanderheartTrollkinOutlaw.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Bumbles',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/Bumbles.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 1,
  },
  {
    name: 'Fell Caller Hero',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/FellCallerHero.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Fennblade Kithkar',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/FennbladeKithkar.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Fire Guardian',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/FireGuardian.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 0,
  },
  {
    name: 'Fugue Walker',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/FugueWalker.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'General "Thunderstone" Brug',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/GeneralThunderstoneBrug.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Horgle Ironstrike',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/HorgleIronstrike.jpg',
    size: '40mm',
    health: 8,
    fury: 4,
    maxMeleeRange: 4,
  },
  {
    name: 'Horthol, Long Rider Hero',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/HortholLongRiderHero.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Horthol, Long Rider Hero (Dismount)',
        avatar: '/Warmahordes/avatars/HortholLongRiderHero.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Janissa Stonetide',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/JanissaStonetide.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Madrak Ironhide, Champion of the Kriels',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/MadrakIronhideChampionOfTheKriels.png',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
    fury: 4,
  },
  {
    name: "Mawga'Bawza",
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/MawgaBawza.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Northkin Shaman',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/NorthkinShaman.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Stone Scribe Chronicler',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/StoneScribeChronicler.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Troll Whelps',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollWhelps.jpg',
    size: '30mm',
    count: 2,
  },
  {
    name: 'Trollkin Champion Hero',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinChampionHero.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Gunnery Sergeant',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinGunnerySergeant.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Trollkin Runebearer',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinRunebearer.jpg',
    size: '40mm',
    health: 5,
  },
  {
    name: 'Trollkin Skinner',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/TrollkinSkinner.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Valka Curseborn, Chieftain of the North',
    type: 'Solo',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/ValkaCursebornChieftainoftheNorth.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Hearthgut Hooch Hauler',
    type: 'Battle Engine',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/HearthgutHoochHauler.jpg',
    size: '120mm',
    health: 38,
    maxMeleeRange: 1,
  },
  {
    name: 'War Wagon',
    type: 'Battle Engine',
    faction: 'Trollbloods',
    avatar: '/Warmahordes/avatars/WarWagon.jpg',
    size: '120mm',
    health: 38,
    maxMeleeRange: 1,
  },
  {
    name: 'Baldur the Stonecleaver',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/BaldurtheStonecleaver.jpg',
    size: '30mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Baldur the Stonesoul',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/BaldurtheStonesoul.jpg',
    size: '30mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Wurm',
  },
  {
    name: 'Bradigus Thorle the Runecarver',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/BradigusThorletheRunecarver.jpg',
    size: '50mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Synergy',
  },
  {
    name: 'Grayle the Farstrider',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/GrayletheFarstrider.jpg',
    size: '30mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Iona the Unseen',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/IonatheUnseen.jpg',
    size: '30mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Kaya the Moonhunter',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KayatheMoonhunter.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    includes: [
      {
        name: 'Laris',
        avatar: '/Warmahordes/avatars/Laris.jpg',
        size: '40mm',
        damageGrid: 'MM\nMM\nMM\nBB\nBB\nBB\nSSS\nSS\nSSS',
        fury: 0,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Kaya the Wildborne',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KayatheWildborne.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Kaya the Wildheart',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KayatheWildheart.jpg',
    size: '50mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Synergy',
  },
  {
    name: 'Kromac the Ravenous',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KromactheRavenous.jpg',
    size: '40mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Kromac, Champion of the Wurm',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KromacChampionoftheWurm.jpg',
    size: '50mm',
    health: 19,
    fury: 7,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Corpses',
  },
  {
    name: 'Krueger the Stormlord',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KruegertheStormlord.jpg',
    size: '30mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Krueger the Stormwrath',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KruegertheStormwrath.jpg',
    size: '30mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Mohsar the Desertwalker',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/MohsartheDesertwalker.jpg',
    size: '30mm',
    health: 15,
    fury: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Morvahna the Autumnblade',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/MorvahnatheAutumnblade.jpg',
    size: '30mm',
    health: 15,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Morvahna the Dawnshadow',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/MorvahnatheDawnshadow.jpg',
    size: '50mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Tanith the Feral Song',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TaniththeFeralSong.jpg',
    size: '30mm',
    health: 15,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Una the Skyhunter',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/UnatheSkyhunter.jpg',
    size: '30mm',
    health: 15,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Wurmwood, Tree of Fate & Cassius the Oathkeeper',
    type: 'Warlock',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WurmwoodTreeofFateCassiustheOathkeeper.jpg',
    size: '50mm',
    alias: 'Wurmwood, Tree of Fate',
    health: 20,
    fury: 7,
    includes: [
      {
        name: 'Cassius the Oathkeeper',
        avatar: '/Warmahordes/avatars/CassiustheOathkeeper.jpg',
        size: '30mm',
        health: 10,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Argus Moonhound',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ArgusMoonhound.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Brennos the Elderhorn',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/BrennostheElderhorn.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Feral Warpwolf',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/FeralWarpwolf.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Ghetorix',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Ghetorix.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMM\nBBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Gnarlhorn Satyr',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/GnarlhornSatyr.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Gorax Rager',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/GoraxRager.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Loki',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Loki.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Megalith',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Megalith.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBBB\nBB\nBBBBBB\nSSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Pureblood Warpwolf',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/PurebloodWarpwolf.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBBB\nSSSSS\nSS\nSSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Razorwing Griffon',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/RazorwingGriffon.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Rip Horn Satyr',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/RipHornSatyr.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Rotterhorn Griffon',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/RotterhornGriffon.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Scarsfell Griffon',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ScarsfellGriffon.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Shadowhorn Satyr',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ShadowhornSatyr.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Storm Raptor',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/StormRaptor.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMMM\nMM\nMMMMMMM\nBBBBBB\nBB\nBBBBBB\nSSSSSSSSS\nSS\nSSSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Warpwolf Stalker',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WarpwolfStalker.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBB\nBB\nBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Wild Argus',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WildArgus.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Winter Argus',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WinterArgus.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Wold Guardian',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WoldGuardian.jpg',
    size: '50mm',
    damageGrid: 'MMMMM\nMM\nMMMM\nBBBBBB\nBB\nBBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Woldwight',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WoldWight.jpg',
    size: '40mm',
    damageGrid: 'M\nMM\nM\nBB\nBB\nB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Woldwarden',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Woldwarden.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBBB\nBB\nBBBBBB\nSSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Woldwatcher',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Woldwatcher.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Woldwrath',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Woldwrath.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMM\nMM\nMMMMMM\nBBBBBBBBBBB\nBB\nBBBBBBBBBBB\nSSSSSSSSS\nSS\nSSSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Woldwyrd',
    type: 'Warbeast',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Woldwyrd.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBB\nBB\nBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Bríghid & Caul',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Brighid.jpg',
    size: '30mm',
    health: 5,
    alias: 'Bríghid',
    includes: [
      {
        name: 'Caul',
        avatar: '/Warmahordes/avatars/Caul.jpg',
        size: '40mm',
        health: 8,
        maxMeleeRange: 2,
        resource3Label: 'Corpses',
        resource3: 0,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Death Wolves',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Skoll.jpg',
    size: '30mm',
    health: 5,
    alias: 'Sköll',
    resource3Label: 'Corpses',
    resource3: 0,
    includes: [
      {
        name: 'Tala',
        avatar: '/Warmahordes/avatars/Tala.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
        resource3Label: 'Corpses',
        resource3: 0,
      },
      {
        name: 'Caleb',
        avatar: '/Warmahordes/avatars/Caleb.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
        resource3Label: 'Corpses',
        resource3: 0,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Druid Mist Riders',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/DruidMistRiders.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Druid of Orboros Overseer',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/DruidofOrborosOverseer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Druids of Orboros',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/DruidsofOrboros.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Nuala the Huntress',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/NualatheHuntress.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Reeve of Orboros Chieftain & Standard',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ReeveofOrborosChieftainStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Reeve of Orboros Chieftain',
    includes: [
      {
        name: 'Reeve of Orboros Standard Bearer',
        avatar: '/Warmahordes/avatars/ReeveofOrborosChieftainStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Reeves of Orboros',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ReevesofOrboros.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Sentry Stone & Mannikins',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/SentryStone.jpg',
    size: '40mm',
    health: 8,
    alias: 'Sentry Stone',
    fury: 0,
    includes: [
      {
        name: 'Mannikin',
        avatar: '/Warmahordes/avatars/Mannikin.jpg',
        size: '30mm',
        count: 3,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Shifting Stones',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ShiftingStones.jpg',
    size: '30mm',
    health: 5,
    count: 3,
  },
  {
    name: 'Stone Keeper',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/StoneKeeper.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Stoneward & Woldstalkers',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Stoneward.jpg',
    size: '30mm',
    alias: 'Stoneward',
    includes: [
      {
        name: 'Woldstalker',
        avatar: '/Warmahordes/avatars/Woldstalker.jpg',
        size: '30mm',
        count: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Tharn Blood Pack',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnBloodPack.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Tharn Bloodtrackers',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnBloodtrackers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Tharn Bloodweaver Haruspex',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnBloodweaverHaruspex.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Tharn Bloodweavers',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnBloodweavers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Tharn Ravager Chieftain',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnRavagerChieftain.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Tharn Ravagers',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnRavagers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Tharn Wolf Riders',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnWolfRiders.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Warpborn Skinwalker Alpha',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WarpbornSkinwalkerAlpha.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Warpborn Skinwalkers',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WarpbornSkinwalkers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Wolves of Orboros',
    type: 'Unit',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WolvesofOrboros.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Wolves of Orboros Chieftain & Standard',
    type: 'Command Attachment',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WolvesofOrborosChieftainStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Wolves of Orboros Chieftain',
    includes: [
      {
        name: 'Wolves of Orboros Standard Bearer',
        avatar: '/Warmahordes/avatars/WolvesofOrborosChieftainStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Blackclad Stoneshaper',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/BlackcladStoneshaper.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Blackclad Wayfarer',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/BlackcladWayfarer.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Bloodweaver Night Witch',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/BloodweaverNightWitch.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Chuck Dogwood',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ChuckDogwood.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Druid Wilder',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/DruidWilder.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Gallows Grove',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/GallowsGrove.jpg',
    size: '40mm',
    health: 5,
  },
  {
    name: 'Krueger the Stormseer',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/KruegerTheStormseer.png',
    size: '30mm',
    health: 5,
    fury: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Lord of the Feast',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/LordoftheFeast.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Reeve Hunter',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/ReeveHunter.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Shivers',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/Shivers.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Tharn Blood Shaman',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnBloodShaman.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Tharn Ravager Shaman',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnRavagerShaman.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Tharn Ravager White Mane',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnRavagerWhiteMane.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
    resource3Label: 'Corpses',
    resource3: 0,
  },
  {
    name: 'Tharn Wolf Rider Champion',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/TharnWolfRiderChampion.jpg',
    size: '50mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Una the Falconer',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/UnatheFalconer.jpg',
    size: '30mm',
    health: 5,
    fury: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'War Wolf',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WarWolf.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Wolf Lord Morraig',
    type: 'Solo',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WolfLordMorraig.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Wolf Lord Morraig (Dismount)',
        avatar: '/Warmahordes/avatars/WolfLordMorraig.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Celestial Fulcrum',
    type: 'Battle Engine',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/CelestialFulcrum.jpg',
    size: '120mm',
    health: 28,
    fury: 0,
  },
  {
    name: 'Well of Orboros',
    type: 'Structure',
    faction: 'Circle Orboros',
    avatar: '/Warmahordes/avatars/WellofOrboros.jpg',
    size: '120mm',
    health: 32,
  },
  {
    name: 'Archdomina Makeda',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/ArchdominaMakeda.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Blood Quenched',
  },
  {
    name: 'Beast Master Xekaar',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/BeastMasterXekaar.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Dominar Morghoul & Escorts',
    type: 'Warlock Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/DominarMorghoulEscorts.jpg',
    size: '30mm',
    health: 15,
    fury: 6,
    alias: 'Dominar Morghoul',
    includes: [
      {
        name: 'Escort',
        avatar: '/Warmahordes/avatars/MorghoulEscorts.jpg',
        size: '30mm',
        health: 5,
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Dominar Rasheth',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/DominarRasheth.jpg',
    size: '50mm',
    health: 20,
    fury: 8,
  },
  {
    name: 'Lord Arbiter Hexeris',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/LordArbiterHexeris.jpg',
    size: '30mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Lord Assassin Morghoul',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/LordAssassinMorghoul.jpg',
    size: '30mm',
    health: 15,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Lord Tyrant Hexeris',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/LordTyrantHexeris.jpg',
    size: '30mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Lord Tyrant Zaadesh',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/LordTyrantZaadesh.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Makeda & the Exalted Court',
    type: 'Warlock Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/MakedatheExaltedCourt.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    alias: 'Makeda3',
    includes: [
      {
        name: 'Exalted Guardan',
        avatar: '/Warmahordes/avatars/ExaltedGuardian.jpg',
        size: '30mm',
        health: 5,
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Master Ascetic Naaresh',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/MasterAsceticNaaresh.jpg',
    size: '30mm',
    health: 20,
    fury: 6,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Blood',
  },
  {
    name: 'Master Tormentor Morghoul',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/MasterTormentorMorghoul.jpg',
    size: '30mm',
    health: 15,
    fury: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Primus Jalaam',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PrimusJalaam.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Supreme Aptimus Zaal',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/SupremeAptimusZaal.jpg',
    size: '30mm',
    health: 15,
    fury: 7,
    includes: [
      {
        name: 'Kovaas',
        avatar: '/Warmahordes/avatars/Kovaas.jpg',
        size: '40mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Supreme Archdomina Makeda',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/SupremeArchdominaMakeda.jpg',
    size: '30mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Tyrant Xerxis',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TyrantXerxis.jpg',
    size: '40mm',
    health: 19,
    fury: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Void Seer Mordikaar',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VoidSeerMordikaar.jpg',
    size: '30mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Xerxis, Fury of Halaak',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/XerxisFuryofHalaak.jpg',
    size: '120mm',
    health: 24,
    fury: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Zaal, the Ancestral Advocate',
    type: 'Warlock',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/ZaaltheAncestralAdvocate.jpg',
    size: '40mm',
    health: 18,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Agonizer',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Agonizer.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nB\nBB\nB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Aradus Scarab',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/AradusScarab.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nBB\nBB\nB\nS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Aradus Sentinel',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/AradusSentinel.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMM\nBBBBBB\nBB\nBBBBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Aradus Soldier',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/AradusSoldier.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMM\nBBBBBB\nBB\nBBBBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Archidon',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Archidon.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMM\nBBBB\nBB\nBBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Basilisk Drake',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/BasiliskDrake.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Basilisk Krea',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/BasiliskKrea.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBB\nBB\nB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Bronzeback Titan',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/BronzebackTitan.jpg',
    size: '50mm',
    damageGrid: 'MMMMM\nMM\nMMM\nBBBBBB\nBB\nBBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Chiron',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Chiron.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Cyclops Brute',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/CyclopsBrute.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Cyclops Raider',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/CyclopsRaider.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Cyclops Savage',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/CyclopsSavage.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBBB\nBB\nBBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Cyclops Shaman',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/CyclopsShaman.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBB\nBB\nBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Desert Hydra',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/DesertHydra.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMM\nMM\nMMMMMM\nBBBBBBBBBB\nBB\nBBBBBBBBBB\nSSSSSSSS\nSS\nSSSSSSSSS',
    fury: 0,
    maxMeleeRange: 3,
  },
  {
    name: 'Despoiler',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Despoiler.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Mammoth',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Mammoth.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMM\nMM\nMMMMM\nBBBBBBBBBBB\nBB\nBBBBBBBBBBB\nSSSSSSSSS\nSS\nSSSSSSSS',
    fury: 0,
    maxMeleeRange: 3,
  },
  {
    name: 'Molik Karn',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/MolikKarn.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Razor Worm',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/RazorWorm.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nM\nBBB\nBB\nBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Reptile Hound',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/ReptileHound.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nBB\nBB\nB\nS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Rhinodon',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Rhinodon.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'The Terrorizer',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TheTerrorizer.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nBB\nBB\nB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Tiberion',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Tiberion.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Titan Cannoneer',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TitanCannoneer.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Titan Gladiator',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TitanGladiator.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Titan Sentry',
    type: 'Warbeast',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TitanSentry.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Cataphract Arcuarii',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/CataphractArcuarii.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Cataphract Cetrati',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/CataphractCetrati.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Cataphract Incindiarii',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/CataphractIncindiarii.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Extoller Advocate',
    type: 'Command Attachment',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/ExtollerAdvocate.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Souls',
  },
  {
    name: 'Immortals',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Immortals.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Legends of Halaak',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Valgesh.jpg',
    size: '30mm',
    health: 5,
    alias: 'Valgesh',
    includes: [
      {
        name: 'Cidaar',
        avatar: '/Warmahordes/avatars/Cidaar.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
      {
        name: 'J’Deth',
        avatar: '/Warmahordes/avatars/Jdeth.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Nihilators',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/Nihilators.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Paingiver Beast Handlers',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PaingiverBeastHandlers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Paingiver Bloodrunners',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PaingiverBloodrunners.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Praetorian Ferox',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PraetorianFerox.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Praetorian Karax',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PraetorianKarax.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Praetorian Karax Officer & Standard',
    type: 'Command Attachment',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PraetorianKaraxOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Praetorian Karax Officer',
    includes: [
      {
        name: 'Praetorian Karax Standard Bearer',
        avatar: '/Warmahordes/avatars/PraetorianKaraxOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Praetorian Keltarii',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PraetorianKeltarii.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Praetorian Swordsman Officer & Standard',
    type: 'Command Attachment',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PraetorianSwordsmanOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Praetorian Swordsman Officer',
    includes: [
      {
        name: 'Praetorian Swordsman Standard Bearer',
        avatar: '/Warmahordes/avatars/PraetorianSwordsmanOfficerStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Praetorian Swordsmen',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PraetorianSwordsmen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Tyrant Commander & Standard Bearer',
    type: 'Command Attachment',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TyrantCommanderStandardBearer.jpg',
    size: '40mm',
    health: 8,
    alias: 'Tyrant Commander',
    maxMeleeRange: 2,
    includes: [
      {
        name: 'Tyrant Commander Standard Bearer',
        avatar: '/Warmahordes/avatars/TyrantCommanderStandardBearer.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
  },
  {
    name: 'Tyrant Vorkesh',
    type: 'Command Attachment',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TyrantVorkesh.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Venator Catapult Crew',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VenatorCatapultCrew.jpg',
    size: '50mm',
    alias: 'Venator Catapult',
    includes: [
      {
        name: 'Venator Catapult Crew Grunt',
        avatar: '/Warmahordes/avatars/VenatorCatapultCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Venator Flayer Cannon Crew',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VenatorFlayerCannonCrew.jpg',
    size: '50mm',
    alias: 'Venator Flayer Cannon',
    includes: [
      {
        name: 'Venator Flayer Cannon Crew',
        avatar: '/Warmahordes/avatars/VenatorFlayerCannonCrewGrunt.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Venator Reiver Officer & Standard',
    type: 'Command Attachment',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VenatorReiverOfficerStandard.jpg',
    size: '30mm',
    health: 5,
    alias: 'Venator Reiver Officer',
    includes: [
      {
        name: 'Venator Reiver Standard Bearer',
        avatar: '/Warmahordes/avatars/VenatorReiverOfficerStandard.jpg',
        size: '30mm',
        maxMeleeRange: 1,
        standardBearer: true,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Venator Reivers',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VenatorReivers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Venator Slingers',
    type: 'Unit',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VenatorSlingers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Abidan the Keeper',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/AbidantheKeeper.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Ancestral Guardian',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/AncestralGuardian.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Souls',
  },
  {
    name: 'Aptimus Marketh',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/AptimusMarketh.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Extoller Novitiate',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/ExtollerNovitiate.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Extoller Soulward',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/ExtollerSoulward.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Souls',
  },
  {
    name: 'Hakaar the Destroyer',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/HakaartheDestroyer.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Souls',
  },
  {
    name: 'Immortal Vessel',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/ImmortalVessel.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Makeda of House Balaash',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/MakedaOfHouseBalaash.png',
    size: '30mm',
    health: 5,
    fury: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Mortitheurge Willbreaker',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/MortitheurgeWillbreaker.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Paingiver Bloodrunner Master Tormentor',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PaingiverBloodrunnerMasterTormentor.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Paingiver Task Master',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/PaingiverTaskMaster.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Tyrant Rhadeim',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TyrantRhadeim.jpg',
    size: '50mm',
    health: 10,
    includes: [
      {
        name: 'Tyrant Rhadeim (Dismount)',
        avatar: '/Warmahordes/avatars/TyrantRhadeim.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Tyrant Zaadesh',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/TyrantZaadesh.jpg',
    size: '30mm',
    health: 5,
    fury: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Venator Dakar',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VenatorDakar.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Void Spirit',
    type: 'Solo',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/VoidSpirit.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Siege Animantarax',
    type: 'Battle Engine',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/SiegeAnimantarax.jpg',
    size: '120mm',
    health: 35,
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Rage',
  },
  {
    name: 'Supreme Guardian',
    type: 'Battle Engine',
    faction: 'Skorne',
    avatar: '/Warmahordes/avatars/SupremeGuardian.jpg',
    size: '120mm',
    health: 33,
    maxMeleeRange: 3,
    resource3: 0,
    resource3Label: 'Souls',
  },
  {
    name: 'Absylonia, Daughter of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/AbsyloniaDaughterofEverblight.jpg',
    size: '40mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Absylonia, Terror of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/AbsyloniaTerrorofEverblight.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Anamag the Doom Feaster',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/AnamagtheDoomFeaster.jpg',
    size: '40mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Bethayne, Voice of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BethayneVoiceofEverblight.jpg',
    size: '30mm',
    health: 15,
    fury: 7,
    includes: [
      {
        name: 'Belphagor',
        avatar: '/Warmahordes/avatars/Belphagor.jpg',
        size: '40mm',
        damageGrid: 'MM\nMM\nMM\nBBBBB\nBB\nBBBBB\nSS\nSS\nSS',
        fury: 0,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Bethayne, Pride of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Bethayne2Render.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBBBB\nBB\nBBBBB\nSS\nSS\nSS',
    fury: 7,
    maxMeleeRange: 1,
    resource3Label: 'Flesh Web',
  },
  {
    name: 'Fyanna, Torment of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/FyannaTormentofEverblight.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 4,
  },
  {
    name: 'Kallus, Devastation of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/KallusDevastationofEverblight.jpg',
    size: '50mm',
    health: 20,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Kallus, Wrath of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/KallusWrathofEverblight.jpg',
    size: '30mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Kryssa, Conviction of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/KryssaConvictionofEverblight.jpg',
    size: '30mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Lylyth, Herald of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/LylythHeraldofEverblight.jpg',
    size: '30mm',
    health: 15,
    fury: 6,
    maxMeleeRange: 1,
    resource3Label: 'Power',
  },
  {
    name: 'Lylyth, Reckoning of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/LylythReckoningofEverblight.jpg',
    size: '120mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Lylyth, Shadow of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/LylythShadowofEverblight.jpg',
    size: '30mm',
    health: 15,
    fury: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Mekanolyth',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Mekanolyth.jpg',
    size: '30mm',
    health: 18,
    fury: 6,
  },
  {
    name: 'Rhyas, Sigil of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/RhyasSigilofEverblight.jpg',
    size: '30mm',
    health: 16,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Saeryn & Rhyas, Talons of Everblight',
    type: 'Warlock Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/SaerynRhyasTalonsofEverblight.jpg',
    size: '30mm',
    health: 8,
    fury: 7,
    alias: 'Saeryn2',
    includes: [
      {
        name: 'Rhyas2',
        avatar: '/Warmahordes/avatars/Rhyas2.jpg',
        size: '30mm',
        health: 8,
        fury: 6,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Saeryn, Omen of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/SaerynOmenofEverblight.jpg',
    size: '30mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Thagrosh, Prophet of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/ThagroshProphetofEverblight.jpg',
    size: '40mm',
    health: 18,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Thagrosh, the Messiah',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/ThagroshtheMessiah.jpg',
    size: '50mm',
    health: 20,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Vayl, Consul of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/VaylConsulofEverblight.jpg',
    size: '30mm',
    health: 15,
    fury: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Vayl, Disciple of Everblight',
    type: 'Warlock',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/VaylDiscipleofEverblight.jpg',
    size: '30mm',
    health: 15,
    fury: 8,
  },
  {
    name: 'Afflictor',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Afflictor.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBB\nBB\nB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Angelius',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Angelius.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Archangel',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Archangel.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMMM\nMM\nMMMMMM\nBBBBBBBB\nBB\nBBBBBBBB\nSSSSSSSS\nSS\nSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Azrael',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Azrael.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBB\nBB\nBBBB\nSSSSS\nSS\nSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Blight Wasp',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightWasp.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nB\nBB\nB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Blightbringer',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Blightbringer.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMMM\nMM\nMMMMMMM\nBBBBBBBBBBB\nBB\nBBBBBBBBBBB\nSSSSSS\nSS\nSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Carnivean',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Carnivean.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Golab',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Golab.jpg',
    size: '50mm',
    damageGrid: 'MMMMM\nMM\nMMMM\nBBBB\nBB\nBB\nSSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Harrier',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Harrier.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nB\nBB\nB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Mekanoshredder',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Mekanoshredder.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nBBB\nBB\nBB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Naga Nightlurker',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/NagaNightlurker.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMM\nBB\nBB\nB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Nephilim Bloodseer',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/NephilimBloodseer.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBB\nBB\nBB\nSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Nephilim Bolt Thrower',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/NephilimBoltThrower.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Nephilim Protector',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/NephilimProtector.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Nephilim Soldier',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/NephilimSoldier.jpg',
    size: '40mm',
    damageGrid: 'MMM\nMM\nMMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Neraph',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Neraph.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Proteus',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Proteus.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 4,
  },
  {
    name: 'Raek',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Raek.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Ravagore',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Ravagore.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Scythean',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Scythean.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Seraph',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Seraph.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Shredder',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Shredder.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nB\nBB\nB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Stinger',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Stinger.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nB\nBB\nB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Teraph',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Teraph.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBB\nBB\nBB\nSSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Typhon',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Typhon.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Zuriel',
    type: 'Warbeast',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Zuriel.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBB\nBB\nBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Bayal, Hound of Everblight',
    type: 'Command Attachment',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BayalHoundofEverblight.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Blackfrost Shard',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Sevryn.jpg',
    size: '30mm',
    health: 5,
    alias: 'Sevryn1',
    includes: [
      {
        name: 'Rhylyss1',
        avatar: '/Warmahordes/avatars/Rhylyss.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
      {
        name: 'Vysarr1',
        avatar: '/Warmahordes/avatars/Vysarr.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Nyss Archer Officer & Ammo Porter',
    type: 'Command Attachment',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssArcherOfficer.jpg',
    size: '30mm',
    health: 5,
    alias: 'Blighted Nyss Archer Officer',
    includes: [
      {
        name: 'Blighted Nyss Archer Ammo Porter',
        avatar: '/Warmahordes/avatars/BlightedNyssArcherAmmoPorter.jpg',
        size: '30mm',
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Nyss Archers',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssArchers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Nyss Hex Hunters',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssHexHunters.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Nyss Legionnaires',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssLegionnaires.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Blighted Nyss Raptors',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssRaptors.jpg',
    size: '50mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Blighted Nyss Scather Crew',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssScatherCrew.jpg',
    size: '50mm',
    alias: 'Blighted Nyss Scather Crew Gunner',
    includes: [
      {
        name: 'Blighted Nyss Scather Crew Grunt',
        avatar: '/Warmahordes/avatars/BlightedNyssScatherCrewGrunt.jpg',
        size: '30mm',
        count: 2,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Nyss Swordsman Abbot & Champion',
    type: 'Command Attachment',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssSwordsmanAbbot.jpg',
    size: '30mm',
    health: 5,
    alias: 'Blighted Nyss Swordsman Abbot',
    includes: [
      {
        name: 'Blighted Nyss Swordsman Champion',
        avatar: '/Warmahordes/avatars/BlightedNyssSwordsmanChampion.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Nyss Swordsmen',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssSwordsmen.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Ogrun Warmongers',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedOgrunWarmongers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Blighted Ogrun Warspears',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedOgrunWarspears.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Blighted Rotwings',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedRotwings.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Captain Farilor & Standard',
    type: 'Command Attachment',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Farilor.jpg',
    size: '30mm',
    health: 5,
    alias: 'Farilor1',
    includes: [
      {
        name: 'Legionnaire Standard Bearer',
        avatar: '/Warmahordes/avatars/LegionairreStandard.jpg',
        size: '30mm',
        standardBearer: true,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Chosen of Everblight',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/ChosenofEverblight.jpg',
    size: '50mm',
    health: 10,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Gorag Rotteneye',
    type: 'Command Attachment',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/GoragRotteneye.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Grotesque Banshees',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/GrotesqueBanshees.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Grotesque Raiders',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/GrotesqueRaiders.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Hellmouth',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/HellmouthMaw.jpg',
    size: '50mm',
    health: 8,
    includes: [
      {
        name: 'Hellmouth Tentacle',
        avatar: '/Warmahordes/avatars/HellmouthTentacle.jpg',
        size: '40mm',
        count: 3,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Ice Witches',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/IceWitches.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Spawning Vessel',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/SpawningVesselPot.jpg',
    size: '40mm',
    health: 10,
    resource3: 0,
    resource3Label: 'Corpses',
    includes: [
      {
        name: 'Spawning Vessel Nyss Grunt',
        avatar: '/Warmahordes/avatars/SpawningVesselGrunt.jpg',
        size: '30mm',
        count: 4,
        maxMeleeRange: 2,
      },
    ],
  },
  {
    name: 'Strider Blightblades',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/StriderBlightblades.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Strider Scout Officer & Musician',
    type: 'Command Attachment',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/StriderScoutOfficer.jpg',
    size: '30mm',
    health: 5,
    alias: 'Strider Scout Officer',
    includes: [
      {
        name: 'Strider Scout Musician',
        avatar: '/Warmahordes/avatars/StriderScoutMusician.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Strider Scouts',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/StriderScouts.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Virtue Host',
    type: 'Unit',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/VirtueHostConcept.jpg',
    size: '30mm',
    count: 3,
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Warspear Chieftain',
    type: 'Command Attachment',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/WarspearChieftain.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Ammok the Truthbearer',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/AmmoktheTruthbearer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Annyssa Ryvaal',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/AnnyssaRyvaal.jpg',
    size: '50mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Beast Mistress',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BeastMistress.jpg',
    size: '30mm',
    health: 5,
    fury: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Blight Archon',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightArchonRender.jpg',
    size: '50mm',
    health: 10,
    fury: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Blighted Nyss Shepherd',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssShepherd.jpg',
    size: '30mm',
    maxMeleeRange: 2,
  },
  {
    name: 'Blighted Nyss Sorceress & Hellion',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssSorceressHellion.jpg',
    size: '50mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Blighted Nyss Warlord',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/BlightedNyssWarlord.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Craelix, Fang of Everblight',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/CraelixFangofEverblight.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Fyanna the Lash',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/FyannatheLash.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 4,
  },
  {
    name: 'Grotesque Assassin',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/GrotesqueAssassin.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Incubus',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Incubus.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Spell Martyr',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/SpellMartyr.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Strider Deathstalker',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/StriderDeathstalker.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Succubus',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Succubus.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Thagrosh Hellborne',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/ThagroshHellborne.png',
    size: '40mm',
    health: 8,
    fury: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Forsaken',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/TheForsaken.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
    fury: 0,
  },
  {
    name: 'Virtue Champion',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/VirtueChampionConcept.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Yssylla',
    alias: 'Ysylla',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/Yssylla.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Warmonger War Chief',
    type: 'Solo',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/WarmongerWarChief.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Throne of Everblight',
    type: 'Battle Engine',
    faction: 'Legion of Everblight',
    avatar: '/Warmahordes/avatars/ThroneofEverblight.jpg',
    size: '120mm',
    health: 36,
    maxMeleeRange: 3,
  },
  {
    name: 'Barnabas, Lord of Blood',
    type: 'Warlock Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BarnabasLordofBlood.jpg',
    size: '50mm',
    health: 18,
    fury: 7,
    includes: [
      {
        name: 'Bone Shaker',
        avatar: '/Warmahordes/avatars/BoneShaker.jpg',
        size: '30mm',
        count: 3,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
    resource3: 0,
    resource3Label: 'Corpses',
  },
  {
    name: 'Bloody Barnabas',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BloodyBarnabas.jpg',
    size: '40mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Calaban, the Grave Walker',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/CalabantheGraveWalker.jpg',
    size: '40mm',
    health: 15,
    fury: 7,
    maxMeleeRange: 2,
    resource3Label: 'Power',
  },
  {
    name: 'Dr. Arkadius',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/DrArkadius.jpg',
    size: '30mm',
    health: 15,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Helga on Wheels',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/HelgaonWheels.jpg',
    size: '50mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Helga the Conqueror',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/HelgatheConqueror.jpg',
    size: '30mm',
    health: 15,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Jaga-Jaga, the Death Charmer',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/JagaJagatheDeathCharmer.jpg',
    size: '40mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Lord Azazello, The Castellan',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/LordAzazello.jpg',
    size: '30mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Lord Carver, BMMD, Esq. III',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/LordCarverBMMDEsqIII.jpg',
    size: '30mm',
    health: 17,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Maelok the Dreadbound',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/MaeloktheDreadbound.jpg',
    size: '40mm',
    health: 17,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Midas',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Midas.jpg',
    size: '30mm',
    health: 16,
    fury: 7,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Corpses',
  },
  {
    name: 'Rask',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Rask.jpg',
    size: '30mm',
    health: 15,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Sturm & Drang',
    type: 'Warlock',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SturmDrang.jpg',
    size: '40mm',
    health: 18,
    fury: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Battle Boar',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BattleBoar.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBBB\nBB\nBBBB\nSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Blackhide Wrastler',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BlackhideWrastler.jpg',
    size: '50mm',
    damageGrid: 'MM\nM\nM\nBBBBBB\nBB\nBBBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Blind Walker',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BlindWalker.jpg',
    size: '50mm',
    damageGrid: 'MM\nM\nM\nBBBBBB\nBB\nBBBBBBB\nSSSS\nSS\nSSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Boneswarm',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Boneswarm.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nM\nBBBB\nBB\nBBB\nSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Corpses',
  },
  {
    name: 'Bull Snapper',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BullSnapper.jpg',
    size: '40mm',
    damageGrid: 'M\nMM\nM\nBBB\nBB\nBB\nSS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dracodile',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Dracodile.jpg',
    size: '120mm',
    damageGrid:
      'MMMMMMM\nMM\nMMMMMMM\nBBBBBBBBB\nBB\nBBBBBBBBB\nSSSSSSSS\nSS\nSSSSSSSS',
    fury: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Gun Boar',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GunBoar.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBBB\nBB\nBBBB\nSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Ironback Spitter',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/IronbackSpitter.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Razor Boar',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/RazorBoar.jpg',
    size: '30mm',
    damageGrid: 'M\nMM\nM\nBB\nBB\nBB\nS\nSS\nS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Road Hog',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/RoadHog.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMM\nBBBBBB\nBB\nBBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Splatter Boar',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SplatterBoar.jpg',
    size: '40mm',
    damageGrid: 'MM\nMM\nMM\nBBBB\nBB\nBBBB\nSS\nSS\nSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Swamp Horror',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SwampHorror.jpg',
    size: '50mm',
    damageGrid: 'MMMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 4,
  },
  {
    name: 'War Hog',
    type: 'Warbeast',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/WarHog.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMM\nBBBBBB\nBB\nBBBBBB\nSSSS\nSS\nSSS',
    fury: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Bog Trog Ambushers',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BogTrogAmbushers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Boil Master & Spirit Cauldron',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SpiritCauldron.jpg',
    size: '50mm',
    health: 10,
    alias: 'Spirit Cauldron',
    resource3: 0,
    resource3Label: 'Corpses',
    includes: [
      {
        name: 'Boil Master',
        avatar: '/Warmahordes/avatars/BoilMaster.jpg',
        size: '40mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
  },
  {
    name: 'Croak Raiders',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/CroakRaiders.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Croak Trappers',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/CroakTrappers.jpg',
    size: '30mm',
    count: 2,
    maxMeleeRange: 1,
  },
  {
    name: 'Farrow Bone Grinders',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowBoneGrinders.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Farrow Brigand Warlord',
    type: 'Command Attachment',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowBrigandWarlord.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Farrow Brigands',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowBrigands.jpg',
    size: '30mm',
    maxMeleeRange: 1,
    count: 5,
  },
  {
    name: 'Farrow Commandos',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowCommandos.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Farrow Razorback Crew',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowRazorbackCrew.jpg',
    size: '50mm',
    alias: 'Farrow Razorback Gunner',
    includes: [
      {
        name: 'Farrow Razorback Crew Grunt',
        avatar: '/Warmahordes/avatars/FarrowRazorbackCrewGrunt.jpg',
        size: '30mm',
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Farrow Slaughterhousers',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowSlaughterhousers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Farrow Valkyries',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowValkyries.jpg',
    size: '30mm',
    health: 5,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Gatorman Bokor & Bog Trog Swamp Shamblers',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GatormanBokor.jpg',
    size: '40mm',
    health: 5,
    alias: 'Gatorman Bokor',
    resource3: 0,
    resource3Label: 'Corpses',
    includes: [
      {
        name: 'Bog Trog Swamp Shamblers',
        avatar: '/Warmahordes/avatars/SwampShambler.jpg',
        size: '30mm',
        count: 9,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Gatorman Posse',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GatormanPosse.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Lynus Wesselbaum & Edrea Lloryrr',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Lynus.jpg',
    size: '30mm',
    health: 5,
    alias: 'Lynus Wesselbaum',
    includes: [
      {
        name: 'Edrea Lloryrr',
        avatar: '/Warmahordes/avatars/Edrea.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Swamp Gobber Bellows Crew',
    type: 'Unit',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SwampGobberBellowsCrew.jpg',
    size: '30mm',
    count: 2,
    maxMeleeRange: 1,
  },
  {
    name: 'Void Leech',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/VoidLeech.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Agata, Queen of Carnage',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/AgataQueenofCarnage.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Alten Ashley',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/AltenAshley.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Barnabas the Conqueror',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BarnabasTheConqueror.jpg',
    size: '40mm',
    health: 8,
    fury: 4,
    maxMeleeRange: 1,
    resource3Label: 'Blood',
    resource3: 0,
  },
  {
    name: 'Bog Trog Mist Speaker',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BogTrogMistSpeaker.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Bog Trog Trawler',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BogTrogTrawler.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Bone Shrine',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BoneShrine.jpg',
    size: '40mm',
    health: 8,
    fury: 0,
  },
  {
    name: 'Brun Cragback & Lug',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/BrunCragbackLug.jpg',
    size: '30mm',
    alias: 'Brun Cragback',
    health: 8,
    fury: 4,
    includes: [
      {
        name: 'Lug',
        avatar: '/Warmahordes/avatars/Lug.jpg',
        size: '50mm',
        damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBB\nSSS\nSS\nSSS',
        fury: 0,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Croak Hunter',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/CroakHunter.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Dahlia Hallyr & Skarath',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/DahliaHallyrSkarath.jpg',
    size: '30mm',
    health: 5,
    fury: 4,
    alias: 'Dahlia Hallyr',
    includes: [
      {
        name: 'Skarath',
        avatar: '/Warmahordes/avatars/Skarath.jpg',
        size: '50mm',
        damageGrid: 'MMM\nMM\nMM\nBBBBB\nBB\nBBBB\nSSSS\nSS\nSSS',
        fury: 0,
        maxMeleeRange: 2,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Dhunian Archon',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/DhunianArchon.jpg',
    size: '40mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Efaarit Scouts',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/EfaaritScouts.jpg',
    size: '50mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Farrow Sapper',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/FarrowSapper.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Feralgeist',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Feralgeist.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Gatorman Husk',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GatormanHusk.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Gatorman Soul Slave',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GatormanSoulSlave.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Gatorman Witch Doctor',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GatormanWitchDoctor.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Gobber Tinker',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GobberTinker.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Gremlin Swarm',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GremlinSwarm.jpg',
    size: '40mm',
    health: 5,
  },
  {
    name: 'Gudrun the Wanderer',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GudruntheWanderer.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Gudrun the Wasted',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/GudrunTheWasted.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Hutchuk, Ogrun Bounty Hunter',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/HutchukOgrunBountyHunter.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Kogan the Exile',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/KoganTheExile.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Kwaak Slickspine & Gub, Croak Sorcerers',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/KwaakSlickspineGubCroakSorcerers.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Lanyssa Ryssyl, Nyss Sorceress',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/LanyssaRyssylNyssSorceress.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Ledfoot & Tredz',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/LedfootTredz.jpg',
    size: '50mm',
    health: 12,
    maxMeleeRange: 1,
  },
  {
    name: 'Longchops',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Longchops.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Maximus',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Maximus.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Primal Archon',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/PrimalArchon.jpg',
    size: '50mm',
    health: 18,
    maxMeleeRange: 1,
  },
  {
    name: 'Pyg Tank',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/PygTank.jpg',
    size: '50mm',
    health: 12,
    maxMeleeRange: 1,
  },
  {
    name: 'Rorsh & Brine',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/RorshBrine.jpg',
    size: '30mm',
    health: 8,
    fury: 3,
    alias: 'Rorsh',
    includes: [
      {
        name: 'Brine',
        avatar: '/Warmahordes/avatars/Brine.jpg',
        size: '50mm',
        damageGrid: 'MM\nMM\nM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSS',
        fury: 0,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Saxon Orrik',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SaxonOrrik.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Swamp Gobber Chef',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SwampGobberChef.jpg',
    size: '30mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Targ',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Targ.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'The Wastelander',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/TheWastelander.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Thrullg',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/Thrullg.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Totem Hunter',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/TotemHunter.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Underchief Mire',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/UnderchiefMire.jpg',
    size: '30mm',
    health: 5,
    fury: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Viktor Pendrake',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/ViktorPendrake.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Wolf With No Name',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/WolfWithNoName.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
    resource3: 0,
    resource3Label: 'Loot',
  },
  {
    name: 'Wrong Eye & Snapjaw',
    type: 'Solo',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/WrongEyeSnapjaw.jpg',
    size: '40mm',
    health: 8,
    fury: 4,
    alias: 'Wrong Eye',
    includes: [
      {
        name: 'Snapjaw',
        avatar: '/Warmahordes/avatars/Snapjaw.jpg',
        size: '50mm',
        damageGrid: 'MM\nMM\nM\nBBBBBB\nBB\nBBBBBB\nSSS\nSS\nSSS',
        fury: 0,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Meat Thresher',
    type: 'Battle Engine',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/MeatThresher.jpg',
    size: '120mm',
    health: 36,
    maxMeleeRange: 1,
  },
  {
    name: 'Sacral Vault',
    type: 'Battle Engine',
    faction: 'Minions',
    avatar: '/Warmahordes/avatars/SacralVault.jpg',
    size: '120mm',
    health: 30,
  },
  {
    name: 'Horruskh, the Thousand Wraths',
    type: 'Warcaster',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Horruskh.jpg',
    size: '40mm',
    health: 19,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Kishtaar, the Howling Silence',
    type: 'Warcaster',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Kishtaar.jpg',
    size: '40mm',
    health: 16,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Oriax, the Soul Slaver',
    type: 'Warcaster',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/OriaxtheSoulSlaver.jpg',
    size: '40mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Orsus The Betrayed',
    type: 'Warcaster',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/OrsusTheBetrayed.jpg',
    size: '50mm',
    health: 20,
    focus: 6,
    maxMeleeRange: 2,
    resource3Label: 'Blood Quenched',
    resource3: 0,
  },
  {
    name: 'Sabbreth, The Eternal Annihilation',
    type: 'Warcaster',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Sabbreth.jpg',
    size: '40mm',
    health: 17,
    focus: 7,
    maxMeleeRange: 1,
  },
  {
    name: 'Gharlghast',
    type: 'Warjack',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Gharlghast.jpg',
    size: '120mm',
    damageGrid:
      '####LL\n###LLM\n##LLBM\n##HLBM\n#HSSBM\n#HSBBM\n#HSBBM\n#HSSBM\n##HRBM\n###RBM\n###RRM\n####RR',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Jackal',
    type: 'Warjack',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Jackal.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##HMM\n##HCC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Molok',
    type: 'Warjack',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Molok.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n###HMM\n###HCC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Tyrant',
    type: 'Warjack',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Tyrant.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n###HMM\n###HCC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Ravener',
    type: 'Monstrosity',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Ravener.jpg',
    size: '50mm',
    damageGrid: '###L\n##LLM\n####MM\n####BB\n##RRB\n###R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Grhotten Champion',
    type: 'Solo',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/GrhottenChampion.jpg',
    size: '50mm',
    health: 14,
    maxMeleeRange: 2,
  },
  {
    name: 'Grhotten Keeper',
    type: 'Solo',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/GrhottenKeeper.jpg',
    size: '50mm',
    health: 14,
    maxMeleeRange: 2,
  },
  {
    name: 'Halexus the Warlord',
    type: 'Solo',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/HalexustheWarlord.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Reaver Commander',
    type: 'Solo',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/ReaverCommander.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Siege Tarask',
    type: 'Solo',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/SiegeTarask.jpg',
    size: '80mm',
    health: 24,
    maxMeleeRange: 2,
  },
  {
    name: 'Therion Vhanek',
    type: 'Solo',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/TherionVhanek.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Vulcar Forge Master',
    type: 'Solo',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/VulcarForgeMaster.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Assault Reavers',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/AssaultReavers.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Gnashers',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/Gnashers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Reaver Standard',
    type: 'Command Attachment',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/ReaverStandard.jpg',
    size: '40mm',
    maxMeleeRange: 1,
  },
  {
    name: 'Reaver Skirmishers',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/ReaverSkirmishers.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Rhok Harriers',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/RhokHarriers.jpg',
    size: '50mm',
    count: 3,
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Strike Reavers',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/StrikeReavers.jpg',
    size: '40mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Ulkor Axers',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/UlkorAxer.jpg',
    size: '40mm',
    count: 3,
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Ulkor Barragers',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/UlkorBarragers.jpg',
    size: '40mm',
    count: 3,
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Warwitch Coven',
    type: 'Unit',
    faction: 'Orgoth',
    avatar: '/Warmahordes/avatars/WarwitchCoven.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Hazaroth, Narcissar of Ruin',
    type: 'Warcaster',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Hazaroth.jpg',
    size: '50mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Hellyth, Scyir of Nightfall',
    type: 'Warcaster',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Hellyth.jpg',
    size: '30mm',
    health: 15,
    focus: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Morayne, The Blade of Twilight',
    type: 'Warcaster',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Morayne.jpg',
    size: '50mm',
    health: 18,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Scyrafael, Nis-Issyr of Desolations',
    type: 'Warcaster',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Scyrafael.jpg',
    size: '50mm',
    health: 16,
    focus: 7,
    maxMeleeRange: 2,
  },
  {
    name: 'Tyrus, Nis-Arsyr of Spiders',
    type: 'Warcaster',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Tyrus.jpg',
    size: '40mm',
    health: 17,
    focus: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Israfyl',
    type: 'Warjack',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Israfyl.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n###HMM\n###HCC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Ghast',
    type: 'Warjack',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Ghast.jpg',
    size: '40mm',
    damageGrid: '##L\n##LLM\n##HMM\n##HCC\n##RRC\n##R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Eidolon',
    type: 'Warjack',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Eidolon.jpg',
    size: '50mm',
    damageGrid: '###L\n###LLM\n###HMM\n###HCC\n###RRC\n###R',
    focus: 0,
    maxMeleeRange: 2,
  },
  {
    name: 'Specter',
    type: 'Warjack',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Specter.jpg',
    size: '40mm',
    damageGrid: '#L\n#LLM\n##GMM\n##GCC\n#RRC\n#R',
    focus: 0,
    maxMeleeRange: 1,
  },
  {
    name: 'Dreadguard Scyir',
    type: 'Solo',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/DreadguardScyir.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Dyssiss, the Eye of Darkness',
    type: 'Solo',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/Dyssiss.jpg',
    size: '30mm',
    health: 5,
  },
  {
    name: 'Mage Hunter Commander',
    type: 'Solo',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/MageHunterCommander.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Mage Hunter Sniper Team',
    type: 'Solo',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/MageHunterSniperTeam.jpg',
    size: '50mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Vaelyss, The Hand of Mourning',
    type: 'Solo',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/VaelyssTheHandOfMourning.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 0,
    includes: [
      {
        name: 'Avenging Spirit',
        avatar: '/Warmahordes/avatars/AvengingSpirit.jpg',
        health: 8,
        maxMeleeRange: 2,
        size: '40mm',
      },
    ],
  },
  {
    name: 'Void Engine',
    type: 'Solo',
    faction: 'Dusk',
    size: '80mm',
    health: 16,
    avatar: '/Warmahordes/avatars/VoidEngine.jpg',
    includes: [
      {
        name: 'Wight',
        size: '30mm',
        maxMeleeRange: 1,
        avatar: '/Warmahordes/avatars/Wight.jpg',
      },
    ],
  },
  {
    name: 'Void Shaper',
    type: 'Solo',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/VoidShaper.jpg',
    size: '40mm',
    health: 5,
  },
  {
    name: 'Dreadguard Archers',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/DreadguardArchers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Dreadguard Cavalry',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/DreadguardCavalry.jpg',
    size: '50mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Dreadguard Slayers',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/DreadguardSlayers.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Mage Hunter Assassins',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/MageHunterAssassins.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 3,
  },
  {
    name: 'Mage Hunter Rangers',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/MageHunterRangers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Seeker Adepts',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/SeekerAdepts.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Soulless Blademasters',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/SoullessBlademasters.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Soulless Guardians',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/SoullessGuardians.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Soulless Hunters',
    type: 'Unit',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/SoullessHunters.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Seeker Warden',
    type: 'Command Attachment',
    faction: 'Dusk',
    avatar: '/Warmahordes/avatars/SeekerWarden.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Magnus the Unstoppable',
    type: 'Solo',
    faction: 'Mercenaries',
    avatar: '/Warmahordes/avatars/MagnusTheUnstoppable.jpg',
    size: '30mm',
    alias: 'Magnus the Unstoppable',
    health: 10,
    focus: 5,
    includes: [
      {
        name: 'Invictus',
        avatar: '/Warmahordes/avatars/Invictus.jpg',
        size: '80mm',
        damageGrid: '####L\n###LLM\n####MM\n####CC\n###RRC\n####R',
        focus: 0,
        maxMeleeRange: 3,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Kyrrax, the Unrelenting Night',
    type: 'Warlock',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/KyrraxtheUnrelentingNight.jpg',
    size: '50mm',
    health: 20,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Lylyth, the Raven Vengeance',
    type: 'Warlock',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/LylythTheRavenVengence.jpg',
    size: '50mm',
    health: 20,
    fury: 6,
    maxMeleeRange: 0,
  },
  {
    name: 'Nyxyan, the Stygian Coil',
    type: 'Warlock',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Nyxyan.jpg',
    size: '40mm',
    health: 17,
    fury: 8,
    maxMeleeRange: 0,
  },
  {
    name: 'Rassyk, Spawn of Shadows',
    type: 'Warlock',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/RassykSpawnOfShadows.jpg',
    size: '40mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 2,
  },
  {
    name: 'Shyryss, the Flawless Dark',
    type: 'Warlock',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/ShyryssTheFlawlessDark.jpg',
    size: '30mm',
    health: 15,
    fury: 6,
    maxMeleeRange: 0,
  },
  {
    name: "Vallyx, Fate's Eclipse",
    type: 'Warlock',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Vallyx1.jpg',
    size: '40mm',
    health: 18,
    fury: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Aklyss',
    type: 'Warbeast',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Aklyss.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBB\nSSSS\nSS\nSSS',
    maxMeleeRange: 2,
  },
  {
    name: 'Drakyon',
    type: 'Warbeast',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Drakyon.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBBB\nSSSS\nSS\nSSSS',
    maxMeleeRange: 1,
  },
  {
    name: 'Hydrix',
    type: 'Warbeast',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Hydrix.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    maxMeleeRange: 2,
  },
  {
    name: 'Skylla, The Abyssal Fury',
    type: 'Warbeast',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/SkyllaTheAbyssalFury.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBBB\nBB\nBBBBB\nSSSS\nSS\nSSSS',
    maxMeleeRange: 2,
  },
  {
    name: 'Vipex',
    type: 'Warbeast',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Vipex.jpg',
    size: '50mm',
    damageGrid: 'MMM\nMM\nMMM\nBBBB\nBB\nBBB\nSSSS\nSS\nSSS',
    maxMeleeRange: 2,
  },
  {
    name: 'Wyvern',
    type: 'Warbeast',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Wyvern.jpg',
    size: '80mm',
    damageGrid: 'MMMM\nMM\nMMMM\nBBBBBB\nBB\nBBBBB\nSSSSSS\nSS\nSSSSS',
    maxMeleeRange: 3,
  },
  {
    name: 'Ryvyn, The Onyx Rampant',
    type: 'Solo',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Ryvyn.jpg',
    size: '50mm',
    health: 12,
    maxMeleeRange: 2,
  },
  {
    name: 'Incarnate Conjuror',
    type: 'Solo',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/IncarnateConjurer.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 0,
  },
  {
    name: 'Pythia',
    type: 'Solo',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Pythia.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 2,
  },
  {
    name: 'Regulus',
    type: 'Solo',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Regulus.jpg',
    size: '30mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Shadowmancer Scion',
    type: 'Solo',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/ShadowmancerScion.jpg',
    size: '40mm',
    health: 8,
    maxMeleeRange: 1,
  },
  {
    name: 'Spinner',
    type: 'Solo',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Spinner.jpg',
    size: '40mm',
    health: 5,
    maxMeleeRange: 0,
  },
  {
    name: 'Wraithwing Paragon',
    type: 'Solo',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/WraithwingParagon.jpg',
    size: '50mm',
    health: 10,
    maxMeleeRange: 2,
  },
  {
    name: 'Incarnate Knights',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/IncarnateKnight.jpg',
    size: '50mm',
    health: 10,
    count: 3,
    maxMeleeRange: 2,
  },
  {
    name: 'Quick Fang Stalkers',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/QuickFangStalkers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Quick Fang Wind Strikers',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/QuickFangWindStrikers.jpg',
    size: '30mm',
    count: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Shades',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/Klytho.jpg',
    size: '30mm',
    health: 5,
    alias: 'Klytho',
    includes: [
      {
        name: 'Lakysys',
        avatar: '/Warmahordes/avatars/Lakysys.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
      {
        name: 'Atropys',
        avatar: '/Warmahordes/avatars/Atropys.jpg',
        size: '30mm',
        health: 5,
        maxMeleeRange: 1,
      },
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Talon Death Dealers',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/TalonDeathDealers.jpg',
    size: '40mm',
    count: 3,
    health: 8,
    maxMeleeRange: 2,
  },
  {
    name: 'Talon Lashers',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/TalonLashers.jpg',
    size: '40mm',
    count: 3,
    health: 8,
    maxMeleeRange: 3,
  },
  {
    name: 'Wyrmspine Cinderbacks',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/WyrmspineCinderbacks.jpg',
    size: '40mm',
    health: 8,
    count: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Wyrmspine Shadowmancers',
    type: 'Unit',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/WyrmspineShadowmancers.jpg',
    size: '30mm',
    count: 3,
    maxMeleeRange: 0,
  },
  {
    name: 'Quick Fang Master',
    type: 'Command Attachment',
    faction: 'Khymaera',
    avatar: '/Warmahordes/avatars/QuickFangMaster.jpg',
    size: '30mm',
    health: 5,
    maxMeleeRange: 1,
  },
  {
    name: 'Powder Keg',
    type: 'Solo',
    faction: 'Defenses',
    size: '20mm',
  },
];
